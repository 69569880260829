import React, { useState, useEffect } from "react";
import { load } from "@cashfreepayments/cashfree-js";
import {Button,Form,Container,Toast,FloatingLabel} from "react-bootstrap";
import axios from "axios";


const statesList = [
  "Andaman and Nicobar Islands", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", 
  "Dadra and Nagar Haveli and Daman and Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", 
  "Karnataka", "Kerala", "Ladakh", "Lakshadweep", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", 
  "Mizoram", "Nagaland", "Odisha", "Puducherry", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", 
  "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Jammu and Kashmir"
];


const MultiStepForm = ({ selectedCourse }) => {
  const [cashfree, setCashfree] = useState(null);
  const [isCashfreeReady, setIsCashfreeReady] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSingle, setSelectedSingle] = useState("");
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    selectedOption: "",
    fullName: "",
    fatherName: "",
    address: "",
    city: "",
    state: "",
    mobile: "",
    email: "",
    emergencyContact: "",
    dateOfBirth: "", // Added dateOfBirth field
    idType: "", // Added idType field for the selected National ID type
    nationalId: "", // Added nationalId field for the actual ID number
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    load({ mode: "production" })
      .then((cashfreeInstance) => {
        setCashfree(cashfreeInstance);
        setIsCashfreeReady(true);
      })
      .catch(() => {
        setIsCashfreeReady(false);
        alert("Error: Cashfree SDK failed to load.");
      });
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      if (selectedSingle === "Single") {
        if (selectedOption === "oneTime") {
          const onetimeFees = selectedCourse.singleFee * 1.18;
          setTotalAmount(onetimeFees.toFixed(2));
        } else if (selectedOption === "installment") {
          const installmentFees = selectedCourse.singleFee * 0.6 * 1.18;
          setTotalAmount(installmentFees.toFixed(2));
        }
      }
      if (selectedOption === "Group") {
        const groupFees = selectedCourse.groupFee * 1.18;
        setTotalAmount(groupFees.toFixed(2));
      }
    }
  }, [selectedCourse, selectedOption, selectedSingle]);

  const handleOptionSingal = (e) => {
    const selectedValue = e.target.value;
    setSelectedSingle(selectedValue);
    if (selectedValue === "Single") {
      setSelectedOption("");
      setFormData({ ...formData, selectedOption: "" });
    } else {
      setSelectedOption("");
      setFormData({ ...formData, selectedOption: "" });
    }
  };

  const handleOptionChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
    setFormData({ ...formData, selectedOption: option });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextClick = () => {
    if (!selectedOption) {
      setToastMessage("Please select an option to proceed.");
      setToastType("danger");
      setShowToast(true);
      alert("Please select an option to proceed.");
      return;
    }
    setFormData({ ...formData, selectedOption });
    setStep(2);
  };

  const getIdTypeLabel = (idType) => {
    switch (idType) {
      case "aadhaar":
        return "Aadhaar Card";
      case "pan":
        return "PAN Card";
      case "drivingLicense":
        return "Driving License";
      case "voterId":
        return "Voter ID";
      default:
        return "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isCashfreeReady) {
      setToastMessage("Cashfree SDK is still initializing. Please wait.");
      setToastType("danger");
      setShowToast(true);
      alert("Cashfree SDK is still initializing. Please wait.");
      return;
    }

    const form = e.currentTarget;
    const isConfirmed = window.confirm(
      "Please review all fields carefully. Are you sure you want to proceed with the payment?"
    );
    if (!isConfirmed) {
      return;
    }

    if (!cashfree) {
      alert("Error: Cashfree SDK is not available.");
      return;
    }

    const datasends = {
      FullName: formData.fullName,
      FatherName: formData.fatherName,
      Email: formData.email,
      Mobile: formData.mobile,
      EmergencyContact: formData.emergencyContact,
      Address: formData.address,
      State: formData.state,
      City: formData.city,
      Course_Type: selectedOption,
      Course_Name: selectedCourse.name,
      Duration: selectedCourse.duration,
      DOB: formData.dateOfBirth,
      id_number: formData.nationalId,
      id_type: formData.idType,
      Amount: totalAmount,
    };

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setToastMessage("Please fill in all required fields correctly.");
      setToastType("danger");
      setShowToast(true);
      alert("Please fill in all required fields correctly.");
    } else {
      console.log(formData);
       axios.post("https://api.ocupiiehospitalityacademy.com/api/pay", datasends)
        // axios.post("http://127.0.0.1:8000/api/pay", datasends)

        .then((response) => {
          const paymentSessionId = response.data.payment_session_id;

          // Proceed with Cashfree checkout
          const checkoutOptions = {
            paymentSessionId: paymentSessionId,
            redirectTarget: "_self",
          };

          cashfree
            .checkout(checkoutOptions)
            .then((result) => {
              console.log("Cashfree Checkout Result:", result); // Logs all details

              if (result.error) {
                console.log(
                  "User closed the popup or a payment error occurred"
                );
                console.log(result.error);
              }
              if (result.redirect) {
                console.log("Payment will be redirected");
              }
              if (result.paymentDetails) {
                console.log("Payment completed");
              }
            })
            .catch((error) => {
              console.error("Error during payment checkout:", error);
            });
        })
        .catch((error) => {
          console.error("Error sending form data:", error);
        });
    }
    setValidated(true); // Show validation feedback after submission
  };

  return (
    <Container className="mt-4">
      {showToast && (
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          className={`bg-${
            toastType === "success" ? "success" : "danger"
          } text-white`}
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      )}

      {/* Step 1: Select Course Option */}
      {step === 1 && (
        <div className="my-3">
          <p>Select Type of Course</p>
          <Form.Check
            type="radio"
            id="option1"
            name="options"
            value="Single"
            label="Single"
            checked={selectedSingle === "Single"}
            onChange={handleOptionSingal}
            inline
          />
          <Form.Check
            type="radio"
            id="option2"
            name="options"
            value="Group"
            label="Group"
            checked={selectedOption === "Group"}
            onChange={handleOptionChange}
          />
          {selectedSingle === "Single" && (
            <Form.Select
              className="mt-2"
              style={{
                display: "inline-block",
                width: "auto",
                marginRight: "10px",
              }}
              onChange={handleOptionChange}
              aria-label="Single Options"
            >
              <option value="">Select One Time or Installment</option>
              <option value="oneTime">One Time</option>
              <option value="installment">Installment</option>
            </Form.Select>
          )}

          <Button onClick={handleNextClick} variant="primary" className="mt-3">
            Next
          </Button>
        </div>
      )}

      {/* Step 2: Fill out the form */}
      {step === 2 && (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {/* Display selected course in a read-only FloatingLabel input field */}
          <h3>
            <span style={{ fontFamily: "math-italic" }}>
              Fill the Application
            </span>
          </h3>
          {selectedCourse && (
            <FloatingLabel
              controlId="floatingSelectedCourse"
              label="Selected Course"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="selectedCourse"
                value={selectedCourse.name} // Assuming the course object has a 'name' property
                readOnly
              />
            </FloatingLabel>
          )}

          <FloatingLabel
            controlId="floatingSelectedOption"
            label="Selected Option"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="selectedOption"
              // value={formData.selectedOption}
              value={formData.selectedOption}
              readOnly
            />
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingFullName"
            label="Full Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              placeholder="Full Name"
              autoFocus
              required
              pattern="[A-Za-z\s]{3,}" // Only accepts letters and spaces, minimum length of 3
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid name(min.3 characters).
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingFatherName"
            label="Father's Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="fatherName"
              value={formData.fatherName}
              onChange={handleInputChange}
              placeholder="Father's Name"
              required
              pattern="[A-Za-z\s]{3,}" // Only accepts letters and spaces, minimum length of 3
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid father's name(min.3 characters).
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingMobile"
            label="Mobile Number"
            className="mb-3"
          >
            <Form.Control
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              placeholder="Mobile Number"
              required
              pattern="[0-9]{10}" // Only accepts 10-digit numbers
              maxLength={10}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid mobile number(min.10 digit).
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingEmail"
            label="Email"
            className="mb-3"
          >
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              // pattern="[\-a-zA-Z0-9~!$%^&amp;_=+\}\{'?]+(\.[\-a-zA-Z0-9~!$%^&amp;_=+\}\{'?]+)@[a-zA-Z0-9_][\-a-zA-Z0-9_](\.[\-a-zA-Z0-9_]+)*\.[cC][oO][mM](:[0-9]{1,5})?"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingEmergencyContact"
            label="Emergency Contact"
            className="mb-3"
          >
            <Form.Control
              type="tel"
              name="emergencyContact"
              value={formData.emergencyContact}
              onChange={handleInputChange}
              placeholder="Emergency Contact"
              required
              pattern="[0-9]{10}" // Only accepts 10-digit numbers
              maxLength={10}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid emergency contact number(min.10 digit).
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingDateOfBirth"
            label="Date of Birth"
            className="mb-3"
          >
            <Form.Control
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              placeholder="Date of Birth"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid date of birth.
            </Form.Control.Feedback>
          </FloatingLabel>

          {/* National ID Field */}
          <FloatingLabel
            controlId="floatingNationalId"
            label="Select National ID Type"
            className="mb-3"
          >
            <Form.Select
              name="idType"
              value={formData.idType}
              onChange={handleInputChange}
              required
            >
              <option value="">Select an ID Type</option>
              <option value="aadhaar">Aadhaar Card</option>
              <option value="pan">PAN Card</option>
              <option value="drivingLicense">Driving License</option>
              <option value="voterId">Voter ID</option>
            </Form.Select>
          </FloatingLabel>

          {/* National ID Input Field */}
          {formData.idType && (
            <FloatingLabel
              controlId="floatingNationalIdNumber"
              label={`Enter ${getIdTypeLabel(formData.idType)} Number`}
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="nationalId"
                value={formData.nationalId}
                onChange={handleInputChange}
                placeholder={`Enter ${getIdTypeLabel(formData.idType)} Number`}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid {getIdTypeLabel(formData.idType)} number.
              </Form.Control.Feedback>
            </FloatingLabel>
          )}

          <FloatingLabel
            controlId="floatingAddress"
            label="Address"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Address"
              required
              minLength={10}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid address( min. Length 10).
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingState"
            label="State"
            className="mb-3"
          >
            <Form.Select
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              required
            >
              <option value="">Select State</option>
              {statesList.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select a state.
            </Form.Control.Feedback>
          </FloatingLabel>

          <FloatingLabel controlId="floatingCity" label="City" className="mb-3">
            <Form.Control
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="City"
              required
              pattern="[A-Za-z\s]{3,}" // Only accepts letters and spaces, minimum length of 3
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid city(minimum length of 3).
            </Form.Control.Feedback>
          </FloatingLabel>

          {totalAmount > 0 && (
            <FloatingLabel
              controlId="floatingTotalAmount"
              label="Total Amount (including 18% GST):"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="totalAmount"
                value={`₹${totalAmount}`}
                readOnly
              />
            </FloatingLabel>
          )}

          <Button
            variant="secondary"
            onClick={() => {
              setStep(1);
              setSelectedSingle(""); // Reset selectedSingle
              setSelectedOption(""); // Reset selectedOption
              setFormData({ ...formData, selectedOption: "" }); // Clear formData selectedOption
            }}
            className="me-2"
          >
            Back
          </Button>

          <Button type="submit" variant="primary">
            Pay and Submit
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default MultiStepForm;
