import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Card, Form, Row, Col, Button } from "react-bootstrap";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";

// Styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "Helvetica",
  },
  dives: {
    padding: 5,
    border: "1px solid black",
  },
  headerBox: {
    flexDirection: "row", // Keep them in the same row
    justifyContent: "space-between", // Space out the logo and invoice text
    borderBottom: "0.5px solid #000", // Border below the logo9
    alignItems: "center", // Vertically center both items in the row
    width: "100%", // Full width to ensure they spread across
    paddingTop: 0,
    marginBottom: 10,
    //   backgroundColor: "#f8f4ec",
  },
  logoBox: {
    width: "30%", // 35% of the row for the logo
    alignItems: "center", // Center logo vertically
    textAlign: "center", // Center logo horizontally
  },
  logo: {
    width: 130, // Logo size
    height: 130,
  },
  invoiceBox: {
    width: "70%", // 60% of the row for invoice text
    marginTop: 10,
    paddingTop: 30,
    paddingBottom: 23.3,
    alignItems: "center", // Center invoice text vertically
    textAlign: "center", // Center invoice text horizontally
  },
  invoiceText: {
    fontSize: 20,
    fontWeight: "bold",
  },

  section: {
    marginBottom: 10,
  },

  header: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  content: {
    fontSize: 12,
    marginBottom: 20,
  },
  receiptInfo: {
    fontSize: 14,
    marginBottom: 5,
  },
  button: {
    marginTop: 20,
  },
});

const StewardPaymentStatus = () => {
  const [searchParams] = useSearchParams();

  // Initial state based on query parameters
  const [formData, setFormData] = useState({
    fullName: "",
    fatherName: "",
    motherName: "",
    mobile: "",
    email: "",
    applyFor: "",
    emergencyContact: "",
    lastQualification: "",
    yearOfPassing: "",
    applicationId: "",
    district: "",
    amount: "0.00",
    dob: "",
    orderId: "",
    orderStatus: "",
    transactionId: "",
    paymentTime: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      policeStation: "",
      postOffice: "",
      district: "",
    },
  });

  useEffect(() => {
    const queryFields = {};

    searchParams.forEach((value, key) => {
      // Handle nested query parameters like Address[City]
      if (key.includes("[")) {
        const keys = key.split("[").map((k) => k.replace("]", ""));
        // Create a nested structure for address fields
        if (!queryFields[keys[0]]) queryFields[keys[0]] = {};
        queryFields[keys[0]][keys[1]] = value;
      } else {
        queryFields[key] = value;
      }
    });

    setFormData({
      fullName: queryFields.FullName || "",
      fatherName: queryFields.FatherName || "",
      motherName: queryFields.mother_name || "",
      mobile: queryFields.Mobile || "",
      email: queryFields.Email || "",
      applyFor: queryFields.apply_for || "",
      emergencyContact: queryFields.EmergencyContact || "",
      lastQualification: queryFields.last_qualification || "",
      yearOfPassing: queryFields.year_of_passing || "",
      applicationId: queryFields.application_id || "",
      amount: queryFields.Amount || "0.00",
      dob: queryFields.DOB || "",
      orderId: queryFields.order_id || "",
      orderStatus: queryFields.order_status || "",
      transactionId: queryFields.Transaction_id || "",
      paymentTime: queryFields.Payment_time || "",
      address: {
        addressLine1: queryFields.Address?.Address_Line1 || "",
        addressLine2: queryFields.Address?.Address_Line2 || "",
        city: queryFields.Address?.City || "",
        state: queryFields.Address?.State || "",
        postalCode: queryFields.Address?.Postal_Code || "",
        policeStation: queryFields.Address?.Police_Station || "",
        postOffice: queryFields.Address?.Post_Office || "",
        district: queryFields.Address?.District || "",
      },
    });
  }, [searchParams]);

  // Generate the receipt if the payment status is 'paid'
  const generateReceipt = () => {
    const doc = (
      <Document>
        <Page style={styles.page}>
          <View style={styles.dives}>
            <View style={styles.headerBox}>
              {/* Logo Box */}
              <View style={styles.logoBox}>
                <Image
                  style={styles.logo}
                  src="images/ocupielogoblack-png.png" // Replace this with the path to your logo image
                />
              </View>

              {/* Invoice Box */}
              <View style={styles.invoiceBox}>
                <Text style={styles.header}>Payment Receipt</Text>
              </View>
            </View>
            <View style={styles.section}>
              <Text style={styles.receiptInfo}>Name: {formData.fullName}</Text>
              <Text style={styles.receiptInfo}>Amount: ${formData.amount}</Text>
              <Text style={styles.receiptInfo}>Date & Time: {formData.paymentTime}</Text>
              <Text style={styles.receiptInfo}>
                Transaction ID: {formData.transactionId}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );

    // Create a blob from the document and download the file
    pdf(doc)
      .toBlob()
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `receipt_${formData.orderId}.pdf`;
        link.click();
      });
  };

  return (
    <Container className="mt-5">
      <h3 className="mb-4 text-center">Steward Payment Status</h3>

      {/* Display form with query parameters data */}
      <Card>
        <Card.Body>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="fullName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="fatherName">
                  <Form.Label>Father Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherName"
                    value={formData.fatherName}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="motherName">
                  <Form.Label>Mother Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="motherName"
                    value={formData.motherName}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="mobile">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile"
                    value={formData.mobile}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="applyFor">
                  <Form.Label>Apply For</Form.Label>
                  <Form.Control
                    type="text"
                    name="applyFor"
                    value={formData.applyFor}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="emergencyContact">
                  <Form.Label>Emergency Contact</Form.Label>
                  <Form.Control
                    type="text"
                    name="emergencyContact"
                    value={formData.emergencyContact}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="lastQualification">
                  <Form.Label>Last Qualification</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastQualification"
                    value={formData.lastQualification}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="yearOfPassing">
                  <Form.Label>Year of Passing</Form.Label>
                  <Form.Control
                    type="text"
                    name="yearOfPassing"
                    value={formData.yearOfPassing}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="applicationId">
                  <Form.Label>Application ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="applicationId"
                    value={formData.applicationId}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="amount">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="amount"
                    value={formData.amount}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="dob">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    value={formData.dob}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="orderId">
                  <Form.Label>Order ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="orderId"
                    value={formData.orderId}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="orderStatus">
                  <Form.Label>Order Status</Form.Label>
                  <Form.Control
                    type="text"
                    name="orderStatus"
                    value={formData.orderStatus}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Address Fields */}
            <Row>
              <Col md={6}>
                <Form.Group controlId="addressLine1">
                  <Form.Label>Address Line 1</Form.Label>
                  <Form.Control
                    type="text"
                    name="addressLine1"
                    value={formData.address.addressLine1}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="addressLine2">
                  <Form.Label>Address Line 2</Form.Label>
                  <Form.Control
                    type="text"
                    name="addressLine2"
                    value={formData.address.addressLine2}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.address.city}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={formData.address.state}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="postalCode">
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="postalCode"
                    value={formData.address.postalCode}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="policeStation">
                  <Form.Label>Police Station</Form.Label>
                  <Form.Control
                    type="text"
                    name="policeStation"
                    value={formData.address.policeStation}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="postOffice">
                  <Form.Label>Post Office</Form.Label>
                  <Form.Control
                    type="text"
                    name="postOffice"
                    value={formData.address.postOffice}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="district">
                  <Form.Label>District</Form.Label>
                  <Form.Control
                    type="text"
                    name="district"
                    value={formData.address.district}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {/* Show the download button only if the payment status is "paid" */}
      {formData.orderStatus === "paid" && (
        <Button
          variant="primary"
          className="button mt-3"
          onClick={generateReceipt}
        >
          Download Receipt
        </Button>
      )}
    </Container>
  );
};

export default StewardPaymentStatus;
