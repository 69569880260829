import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [userId, setUserId] = useState(""); // Store email or registration ID
  const [otp, setOtp] = useState(""); // Store OTP entered by the user
  const [newPassword, setNewPassword] = useState(""); // Store the new password
  const [confirmPassword, setConfirmPassword] = useState(""); // Store confirm password
  const [step, setStep] = useState(1); // To track which step of the process we are in (1 or 2)
  const [error, setError] = useState(""); // To store any error messages
  const [otpError, setOtpError] = useState(""); // To store OTP error messages
  const [passwordError, setPasswordError] = useState(""); // To store password validation errors
  const [successMessage, setSuccessMessage] = useState(""); // To display success message after resetting password
  const navigate = useNavigate(); // React Router navigation

  // Handle User ID (Email or Registration ID) submission to get OTP
  const handleGetOtp = async (e) => {
    e.preventDefault();
    setError("");
    setOtpError("");

    // Make sure the user ID is not empty
    if (!userId) {
      setError("User ID (email or registration ID) is required");
      return;
    }

    try {
      // Call the backend API to request OTP
      const response = await axios.post("https://api.ocupiiehospitalityacademy.com/api/request-otp", { userId });
      
      if (response.data.success) {
        setStep(2); // Move to the next step to enter OTP and new password
        setSuccessMessage("OTP sent successfully. Please check your email.");
      } else {
        setOtpError(response.data.message || "Failed to send OTP. Please try again.");
      }
    } catch (error) {
      setOtpError("Error requesting OTP. Please try again.");
    }
  };

  // Handle password reset form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setPasswordError(""); // Clear previous errors

    // Validate password and confirm password
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    if (newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters.");
      return;
    }

    try {
      // Call backend API to reset the password with OTP and new password
      const response = await axios.post("https://api.ocupiiehospitalityacademy.com/api/reset-password", {
        userId,
        otp,
        newPassword,
        confirmPassword,
      });

      if (response.data.success) {
        setSuccessMessage("Password reset successfully!");
        setTimeout(() => navigate("/login"), 2000); // Redirect to login page after 2 seconds
      } else {
        setError(response.data.message || "Password reset failed. Please try again.");
      }
    } catch (error) {
      setError("Error resetting password. Please try again.");
    }
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.forgotPasswordForm}>
        <h2 style={styles.title}>Forgot Password</h2>
        
        {error && <p style={{ color: "red" }}>{error}</p>}
        {otpError && <p style={{ color: "red" }}>{otpError}</p>}
        {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
        {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}

        {/* Step 1: Request OTP */}
        {step === 1 && (
          <form onSubmit={handleGetOtp}>
            <div style={styles.inputGroup}>
              <label>User ID (Email or Registration ID)</label>
              <input
                type="text"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                style={styles.inputss}
                required
              />
            </div>
            <button type="submit" style={styles.submitButton}>Get OTP</button>
          </form>
        )}

        {/* Step 2: Enter OTP, New Password, and Confirm Password */}
        {step === 2 && (
          <form onSubmit={handleSubmit}>
            <div style={styles.inputGroup}>
              <label>OTP</label>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                style={styles.inputss}
                required
              />
            </div>
            <div style={styles.inputGroup}>
              <label>New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                style={styles.inputss}
                required
              />
            </div>
            <div style={styles.inputGroup}>
              <label>Re-enter Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={styles.inputss}
                required
              />
            </div>
            <button type="submit" style={styles.submitButton}>Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundImage: `url('/images/login.png')`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  forgotPasswordForm: {
    backgroundColor: '#e4e5e4',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '400px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  inputGroup: {
    marginBottom: '20px',
  },
  inputss: {
    width: '100%',
    padding: '8px',
    marginTop: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  submitButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#4267B2',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
  },
};

export default ForgotPassword;
