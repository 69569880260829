import React from 'react';
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { Col, Container,Button,Row } from 'react-bootstrap';
import CenterSidebar from './CenterSidebar';
import "./CenterDashboard.css";

const CenterDashboard = ()=> {
    const token = localStorage.getItem("centertoken");
    const navigate = useNavigate();
  
    const handleLogout = () => {
      localStorage.removeItem("centertoken"); // Remove token from local storage
      navigate("/"); // Redirect to home page
    };
  
    if (!token) {
      return <Navigate to="/center/logins" />;
    }
  return (
    <Container fluid={true} className="dashboard-containers">
        <Row>
            <Col xs={12} md={3} lg={2} className="sidebar-containers">
             <CenterSidebar/>
            </Col>
            <Col xs={12} md={9} lg={10} className="main-content-containers justify-content-center">
            <Button onClick={handleLogout} style={{ marginBottom: "10px" }}>
                Logout
              </Button>
              <Outlet />
            </Col>
        </Row>
    </Container>
  )
}

export default CenterDashboard