import React, { useState } from 'react';
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

function CenterLogin() {
  const [centerId, setCenterId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [validated, setValidated] = useState(false); // To handle form validation
  const [loading, setLoading] = useState(false); // Loading state to disable button during request
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Form validation check
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return; // If the form is invalid, don't proceed with the login request
    }

    setValidated(true); // Set validated state after form check
    setError(""); // Reset error message before attempting login
    setLoading(true); // Start loading when login request is initiated

    const email = centerId; // Using centerId as email

    try {
      const response = await axios.post("http://127.0.0.1:8000/api/login", { email, password });

      // On successful login, store token and navigate to the center dashboard
      localStorage.setItem("centertoken", response.data.token);
      navigate("/center");
    } catch (error) {
      setLoading(false); // Stop loading if an error occurs

      if (error.response) {
        const { data } = error.response;
        if (data?.error) {
          setError(data.error); // If specific error message is provided
        } else if (data?.errors) {
          const fieldErrors = Object.values(data.errors).flat().join(" ");
          setError(fieldErrors); // If there are validation errors
        } else {
          setError("Login failed: Unknown error"); // General error if nothing specific is returned
        }
      } else if (error.request) {
        setError("Login failed: No response from the server."); // In case of no response
      } else {
        setError("Login failed: " + error.message); // For other errors
      }
    }
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.loginForm}>
        <h2 style={styles.title}>Center Login</h2>

        {error && <p style={{ color: "red" }}>{error}</p>} {/* Display error message */}

        <form noValidate validated={validated.toString()} onSubmit={handleLogin}>
          <div style={styles.inputGroup}>
            <label>Center Id(Email or Id)</label>
            <input
              type="text"
              value={centerId}
              onChange={(e) => setCenterId(e.target.value)}
              style={styles.input}
              required
              isInvalid={validated && !centerId} // Show validation error
            />
            {validated && !centerId && <div style={{ color: "red" }}>Center Id is required</div>}
          </div>

          <div style={styles.inputGroup}>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.input}
              required
              isInvalid={validated && !password} // Show validation error
            />
            {validated && !password && <div style={{ color: "red" }}>Password is required</div>}
          </div>

          <button type="submit" style={styles.loginButton} disabled={loading}>
            {loading ? "Logging in..." : "Login"}
          </button> {/* Show loading text or disable button */}
        </form>

        {/* Forgot Password Link */}
        <div style={styles.forgotPasswordContainers}>
          <Link to="/forgot-password" style={styles.forgotPasswordLinks}>Forgot Password?</Link>
        </div>
      </div>
    </div>
  );
}

const styles = {
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundImage: `url('/images/login.png')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  loginForm: {
    backgroundColor: '#e4e5e4',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '300px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  inputGroup: {
    marginBottom: '20px',
  },
  input: {
    width: '100%',
    padding: '8px',
    marginTop: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  loginButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#4267B2',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
  },
  forgotPasswordContainers: {
    marginTop: '10px',
    textAlign: 'center',
  },
  forgotPasswordLinks: {
    fontSize: '14px',
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default CenterLogin;
