import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import Link

const ParentComponent = () => {
  return (
    <div>
      {/* Use Link for navigation to /stewardapplication */}
      <Link to="/stewardapplication">
        <Image
          src="images/stewardpic.jpg"
          style={{
            position: "fixed", // Fixed position
            left: "10px", // Distance from the left edge
            top: "50%", // Vertically center it on the screen
            transform: "translateY(-50%)", // Adjust to center exactly
            zIndex: 9999, // Ensure the button is above other content
            transition: "all 0.3s ease", // Add smooth transition for responsiveness
          }}
          rounded
          width={300}
          height={100}
          className="responsive-image"
        />
      </Link>

      {/* Add styles for responsiveness */}
      <style>
        {`
          @media (max-width: 576px) {
            .responsive-image {
              width: 200px;
              height: 50px;
              left:-20% !important;
              top: 50%;
              transform: rotate(90deg) translateY(-50%) !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ParentComponent;
