import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";

const AddEmployer = () => {
  const [formData, setFormData] = useState({
    company_name: "",
    employee_name: "",
    street_line_1: "",
    street_line_2: "",
    postal_code: "",
    city: "",
    state: "",
    registration_number: "",
    gst_number: "",
    contact: "",
    email: "",
    contract_date: "",
    type_of_company: "",
    contract_document: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      contract_document: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (let key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    const token = localStorage.getItem("token");

    axios
      .post("https://api.example.com/api/add-employer", formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert("Employer added successfully");
      })
      .catch((error) => {
        console.error("Error adding employer:", error);
        alert("There was an error adding the employer. Please try again.");
      });
  };

  return (
    <Container>
      <Row>
        <Col md={8}>
          <h2>Add New Employer</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="company_name">
              <Form.Label>Company Name*</Form.Label>
              <Form.Control
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="employee_name">
              <Form.Label>Company Employee Name*</Form.Label>
              <Form.Control
                type="text"
                name="employee_name"
                value={formData.employee_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Label>Company Address</Form.Label>
            <Row>
              <Col md={6}>
                <Form.Group controlId="street_line_1">
                  <Form.Label>Street Line 1*</Form.Label>
                  <Form.Control
                    type="text"
                    name="street_line_1"
                    value={formData.street_line_1}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="street_line_2">
                  <Form.Label>Street Line 2</Form.Label>
                  <Form.Control
                    type="text"
                    name="street_line_2"
                    value={formData.street_line_2}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Form.Group controlId="postal_code">
                  <Form.Label>Postal Code*</Form.Label>
                  <Form.Control
                    type="text"
                    name="postal_code"
                    value={formData.postal_code}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="city">
                  <Form.Label>City*</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group controlId="state">
                  <Form.Label>State*</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
                <Col>
                <Form.Group controlId="registration_number">
              <Form.Label>Registration Number*</Form.Label>
              <Form.Control
                type="text"
                name="registration_number"
                value={formData.registration_number}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="gst_number">
              <Form.Label>GST Number*</Form.Label>
              <Form.Control
                type="text"
                name="gst_number"
                value={formData.gst_number}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
     
                <Form.Group controlId="contact">
              <Form.Label>Contact*</Form.Label>
              <Form.Control
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="email">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
                </Col>
            </Row>      
            <Form.Group controlId="contract_date">
              <Form.Label>Contract Date*</Form.Label>
              <Form.Control
                type="date"
                name="contract_date"
                value={formData.contract_date}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="type_of_company">
              <Form.Label>Types of Company*</Form.Label>
              <Form.Control
                type="text"
                name="type_of_company"
                value={formData.type_of_company}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="contract_document">
              <Form.Label>Attach Contract Document (PDF)</Form.Label>
              <Form.Control
                type="file"
                name="contract_document"
                accept=".pdf"
                onChange={handleFileChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AddEmployer;
