import React, { useState } from "react";
import axios from "axios";  // Import Axios
import { Form, Button, Alert, Container, Row, Col, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Import eye icons from react-icons
import {useNavigate } from 'react-router-dom';  // Import useSearchParams from React Router

const VerifyOtp = () => {
  const [otp, setOtp] = useState(""); // State for OTP
  const [password, setPassword] = useState(""); // State for Password
  const [confirmPassword, setConfirmPassword] = useState(""); // State for Confirm Password
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false); // State for confirm password visibility
  const [errorMessage, setErrorMessage] = useState(null); // To store error messages
  const [formErrors, setFormErrors] = useState({}); // To store validation errors
  const [emailOrRegId, setEmailOrRegId] = useState(""); // State for email or registration ID

  // navigate
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors({}); // Reset errors before submitting
  
    // Basic validation
    if (otp.length !== 6) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        otp: "OTP must be exactly 6 digits.",
      }));
      return;
    }
  
    if (password.length < 8) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 8 characters.",
      }));
      return;
    }
  
    if (password !== confirmPassword) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match.",
      }));
      return;
    }

    // Prepare data to send to the backend
    const data = {
      identifier: emailOrRegId, // Include email or registration ID
      otp,
      new_password: password,
      new_password_confirmation: confirmPassword, // Include confirmation
    };
  
    // Send data to backend (OTP, password, confirmation, and email/registration ID)
    axios
      .post('http://127.0.0.1:8000/api/verify-otp-reset-password', data)
      .then((response) => {
        if (response.status === 200) {
          alert("Password reset successfully!");
          navigate("/student/logins");
        } else {
          setErrorMessage(response.data.message || "Password reset failed!");
        }
      })
      .catch((error) => {
        setErrorMessage("Error resetting password");
      });
  };
  
  // Handle OTP input to allow only numbers
  const handleOtpChange = (event) => {
    const value = event.target.value;
    // Ensure only numbers are allowed and limit to 6 digits
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
    }
  };

  // Toggle visibility of password fields
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          {errorMessage && (
            <Alert variant="danger">
              {errorMessage}
            </Alert>
          )}

          {/* OTP, Set Password, Re-enter Password Form */}
          <div>
            <h3>Enter OTP and Set New Password</h3>
            <Form onSubmit={handleSubmit}>
              {/* New Field: Enter Email or Registration ID */}
              <Form.Group controlId="emailOrRegId">
                <Form.Label>Enter Email or Registration ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email or registration ID"
                  value={emailOrRegId}
                  onChange={(e) => setEmailOrRegId(e.target.value)}
                />
              </Form.Group>

              {/* OTP Input */}
              <Form.Group controlId="otp">
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={handleOtpChange}
                  maxLength={6} // Enforces max 6 digits
                  inputMode="numeric" // Restrict input to numeric only
                  pattern="[0-9]*" // Ensure only numbers are entered on mobile
                  isInvalid={!!formErrors.otp}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.otp}
                </Form.Control.Feedback>
              </Form.Group>

              {/* New Password Input */}
              <Form.Group controlId="password">
                <Form.Label>New Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Enter new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={!!formErrors.password}
                  />
                  <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {formErrors.password}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Confirm Password Input */}
              <Form.Group controlId="confirmPassword">
                <Form.Label>Re-enter Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={confirmPasswordVisible ? "text" : "password"}
                    placeholder="Re-enter new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    isInvalid={!!formErrors.confirmPassword}
                  />
                  <InputGroup.Text onClick={toggleConfirmPasswordVisibility} style={{ cursor: "pointer" }}>
                    {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {formErrors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Submit Button */}
              <Button variant="primary" type="submit" className="mt-3">
                Reset Password
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default VerifyOtp;
