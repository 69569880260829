import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { Table, Button, Container, Form, InputGroup, Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

// Employer Component
const Employer = () => {
  const [employerData, setEmployerData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const [companyNameFilter, setCompanyNameFilter] = useState("");
  const [contactFilter, setContactFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedEmployer, setSelectedEmployer] = useState(null);

  // Fetch data from backend
  useEffect(() => {
    const fetchData = () => {
      const token = localStorage.getItem("token");
      axios
      .get("https://api.ocupiiehospitalityacademy.com/api/fetch_enquiries", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setEmployerData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching employer data:", error);
        });
    };
    fetchData();
  }, []);

  // Filter the data
  useEffect(() => {
    const applyFilters = () => {
      const newFilteredData = employerData.filter((item) => {
        const matchesCompanyName = companyNameFilter
          ? item.company_name.toLowerCase().includes(companyNameFilter.toLowerCase())
          : true;
        const matchesContact = contactFilter
          ? item.contact.toLowerCase().includes(contactFilter.toLowerCase())
          : true;
        const matchesEmail = emailFilter
          ? item.email.toLowerCase().includes(emailFilter.toLowerCase())
          : true;

        return matchesCompanyName && matchesContact && matchesEmail;
      });

      setFilteredData(newFilteredData);
      setCurrentPage(1);
    };

    applyFilters();
  }, [companyNameFilter, contactFilter, emailFilter, employerData]);

  // Pagination logic
  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(filteredData.length / recordPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPageRange = () => {
    const start = Math.max(1, currentPage - 2);
    const end = Math.min(totalPages, start + 4);
    const pageRange = [];
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }
    return pageRange;
  };

  // Download data as Excel
  const downloadExcel = () => {
    const dataToDownload = filteredData.length > 0 ? filteredData : employerData;

    const filteredDataForExcel = dataToDownload.map((item) => ({
      Company_Name: item.company_name,
      Contact: item.contact,
      Email: item.email,
      Type_of_Company: item.type_of_company,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const filename = filteredData.length > 0 ? "Filtered_Records" : "All_Records";
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  // Handle view details button click
  const handleViewDetails = (employer) => {
    setSelectedEmployer(employer);
    setShowDetailsModal(true);
  };

  // Handle close modal
  const handleCloseModal = () => {
    setShowDetailsModal(false);
    setSelectedEmployer(null);
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <InputGroup>
            <InputGroup.Text>Company Name</InputGroup.Text>
            <Form.Control
              type="text"
              value={companyNameFilter}
              onChange={(e) => setCompanyNameFilter(e.target.value)}
            />
          </InputGroup>
        </Col>

        <Col>
          <InputGroup>
            <InputGroup.Text>Contact</InputGroup.Text>
            <Form.Control
              type="text"
              value={contactFilter}
              onChange={(e) => setContactFilter(e.target.value)}
            />
          </InputGroup>
        </Col>

        <Col>
          <InputGroup>
            <InputGroup.Text>Email</InputGroup.Text>
            <Form.Control
              type="text"
              value={emailFilter}
              onChange={(e) => setEmailFilter(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <Button variant="success" className="mt-3" onClick={downloadExcel}>
            <i className="fa fa-download" aria-hidden="true"></i> Download Excel
          </Button>
        </Col>

                {/* Add New Employer Button */}
                <Col md={3}>
          <Link to="/bdm/add-employer">
            <Button variant="primary" className="mt-3">
              <i className="fa fa-plus" aria-hidden="true"></i> Add New Employer
            </Button>
          </Link>
        </Col>

      </Row>

      {employerData.length > 0 ? (
        <Container className="text-center">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Company Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Type of Company</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((employer, index) => (
                <tr key={employer.id}>
                  <td>{index + 1 + (currentPage - 1) * recordPerPage}</td>
                  <td>{employer.company_name}</td>
                  <td>{employer.contact}</td>
                  <td>{employer.email}</td>
                  <td>{employer.type_of_company}</td>
                  <td>
                    <Button
                      variant="info"
                      onClick={() => handleViewDetails(employer)}
                    >
                      View Details
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="pagination-controls">
            <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>

            {getPageRange().map((page) => (
              <Button
                key={page}
                onClick={() => paginate(page)}
                active={currentPage === page}
              >
                {page}
              </Button>
            ))}

            <Button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </Container>
      ) : (
        <div>
          <p>No records found matching your criteria. Please try different filters.</p>
        </div>
      )}

      {/* Modal for Viewing Employer Details */}
      <Modal show={showDetailsModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Employer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEmployer ? (
            <div>
              <p><strong>Company Name:</strong> {selectedEmployer.company_name}</p>
              <p><strong>Contact:</strong> {selectedEmployer.contact}</p>
              <p><strong>Email:</strong> {selectedEmployer.email}</p>
              <p><strong>Type of Company:</strong> {selectedEmployer.type_of_company}</p>
              <p><strong>Registration Number:</strong> {selectedEmployer.registration_number}</p>
              <p><strong>GST Number:</strong> {selectedEmployer.gst_number}</p>
              <p><strong>Contract Date:</strong> {selectedEmployer.contract_date}</p>
              {/* Add other details as necessary */}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Employer;
