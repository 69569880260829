import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-bootstrap";
import styles from './StudentProfile.module.css'; // Import the CSS module

const StudentProfile = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Example API URL
    const token = localStorage.getItem("studenttoken");
    axios
      .get("http://127.0.0.1:8000/api/student-details", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setData(response.data.student_profile);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container className={styles.container}>
      <h1 className={styles.title}>Student Profile</h1>
      <div className={styles.profileInfo}>
        <Row>
          <Col>
            <div>
              <label className={styles.label}>Name:</label>
              <div className={styles.value}>{data.name}</div>
            </div>

            <div>
              <label className={styles.label}>Mobile:</label>
              <div className={styles.value}>{data.mobile}</div>
            </div>

            <div>
              <label className={styles.label}>Email:</label>
              <div className={styles.value}>{data.email}</div>
            </div>

            <div>
              <label className={styles.label}>Enrollment ID:</label>
              <div className={styles.value}>{data.Enrollment_Id}</div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default StudentProfile;
