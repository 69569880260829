import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-bootstrap";
import styles from './CenterProfile.module.css'; // Import the CSS module

const CenterProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = () => {
      const token = localStorage.getItem("centertoken");

      axios
        .get("http://127.0.0.1:8000/api/center/profiles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setProfile(response.data.CD_Profile);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
          setError("Failed to fetch profile data");
          setLoading(false);
        });
    };

    fetchProfileData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container className={styles.container}>
      <h1 className={styles.title}>Center Profile</h1>
      <div className={styles.profileInfo}>
        <Row>
          <Col>
            <div>
              <label className={styles.label}>Director Name:</label>
              <div className={styles.value}>{profile.Director_Name}</div>
            </div>

            <div>
              <label className={styles.label}>Center Location:</label>
              <div className={styles.value}>{profile.Center_Location}</div>
            </div>

            <div>
              <label className={styles.label}>Center ID:</label>
              <div className={styles.value}>{profile.Center_Id}</div>
            </div>

            <div>
              <label className={styles.label}>Status:</label>
              <div className={`${styles.status} ${profile.Status === "active" ? styles.active : styles.inactive}`}>
                {profile.Status || "No Status Available"}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default CenterProfile;
