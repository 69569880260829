import React, { useState, useEffect } from "react";
import { Form, Button, Col, Row, Container, Toast } from "react-bootstrap";
import axios from "axios";
import { load } from "@cashfreepayments/cashfree-js";

const StewardApplicationForm = () => {
  const [formData, setFormData] = useState({
    applicant_name: "",
    father_name: "",
    mother_name: "",
    dob: "",
    contact: "",
    emergency_contact: "",
    email: "",
    last_qualification: "10th",
    year_of_passing: "",
    Amount: "2000",
    apply_for: "Hotel Management Steward",
    Address: {
      Address_Line1: "",
      Address_Line2: "",
      City: "",
      State: "",
      Postal_Code: "",
      Police_Station: "",
      Post_Office: "",
      District: "",
    },
    cv: null,
    aadhar: null,
    tenth_admit: null,
    tenth_marksheet: null,
    photo: null,
  });

  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [cashfree, setCashfree] = useState(null);

  useEffect(() => {
    load({ mode: "production" })
      .then((cashfreeInstance) => {
        setCashfree(cashfreeInstance);
      })
      .catch(() => {
        alert("Error: Cashfree SDK failed to load.");
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      Address: { ...formData.Address, [name]: value },
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Frontend validation before submitting
    if (!formData.applicant_name || !formData.father_name || !formData.mother_name) {
      setToastMessage("Please fill in all required fields.");
      setToastType("danger");
      setShowToast(true);
      return;
    }

    if (!formData.dob || !Date.parse(formData.dob)) {
      setToastMessage("Please enter a valid date of birth.");
      setToastType("danger");
      setShowToast(true);
      return;
    }

    if (!formData.contact || formData.contact.length > 15) {
      setToastMessage("Contact number is required and should be a maximum of 15 digits.");
      setToastType("danger");
      setShowToast(true);
      return;
    }

    if (!formData.emergency_contact || formData.emergency_contact.length > 15) {
      setToastMessage("Emergency contact number is required and should be a maximum of 15 digits.");
      setToastType("danger");
      setShowToast(true);
      return;
    }

    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      setToastMessage("Please enter a valid email address.");
      setToastType("danger");
      setShowToast(true);
      return;
    }

    if (!formData.year_of_passing || !/^\d{4}$/.test(formData.year_of_passing)) {
      setToastMessage("Please enter a valid year of passing.");
      setToastType("danger");
      setShowToast(true);
      return;
    }

    if (!formData.Amount || isNaN(formData.Amount)) {
      setToastMessage("Please enter a valid amount.");
      setToastType("danger");
      setShowToast(true);
      return;
    }

    if (!formData.Address.Address_Line1 || !formData.Address.City || !formData.Address.State || !formData.Address.Postal_Code || !formData.Address.Police_Station || !formData.Address.Post_Office || !formData.Address.District) {
      setToastMessage("Please fill in the complete address.");
      setToastType("danger");
      setShowToast(true);
      return;
    }

    if (!formData.cv || !formData.aadhar || !formData.tenth_admit || !formData.tenth_marksheet || !formData.photo) {
      setToastMessage("Please upload all required documents.");
      setToastType("danger");
      setShowToast(true);
      return;
    }

    setLoading(true);

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "Address") {
        Object.keys(formData.Address).forEach((addressKey) =>
          formDataToSend.append(`Address[${addressKey}]`, formData.Address[addressKey])
        );
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });

    axios
      .post("https://api.ocupiiehospitalityacademy.com/api/steward/initiate", formDataToSend, {
        
        headers: { Accept: "application/json" },
      })
      .then((response) => {
        const paymentSessionId = response.data.payment_session_id;

        if (!cashfree) {
          setToastMessage("Error: Cashfree SDK not available.");
          setToastType("danger");
          setShowToast(true);
          return;
        }

        cashfree
          .checkout({ paymentSessionId, redirectTarget: "_self" })
          .then((result) => {
            if (result.error) {
              console.log("Error in payment: ", result.error);
            }
            if (result.redirect) {
              console.log("Redirecting to payment gateway...");
            }
            if (result.paymentDetails) {
              console.log("Payment completed successfully.");
            }
          })
          .catch((error) => {
            console.error("Cashfree checkout error: ", error);
          });
      })
      .catch((error) => {
        console.error("Error submitting form: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      {showToast && (
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1050,
          }}
          className={`bg-${toastType} text-white`}
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      )}

      <h4 className="my-4 mt-5 text-center">Steward Application Form</h4>
      <Form onSubmit={handleSubmit}>
        {/* Applicant Information */}
        <Row>
          <Col md={6}>
            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="Amount"
                value={formData.Amount}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="applyFor">
              <Form.Label>Apply For</Form.Label>
              <Form.Control
                as="select"
                name="apply_for"
                value={formData.apply_for}
                onChange={handleChange}
              >
                <option value="Hotel Management Steward">Hotel Management Steward</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="applicantName">
              <Form.Label>Applicant Name</Form.Label>
              <Form.Control
                type="text"
                name="applicant_name"
                value={formData.applicant_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="fatherName">
              <Form.Label>Father's Name</Form.Label>
              <Form.Control
                type="text"
                name="father_name"
                value={formData.father_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="dob">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="motherName">
              <Form.Label>Mother's Name</Form.Label>
              <Form.Control
                type="text"
                name="mother_name"
                value={formData.mother_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="contact">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                maxLength="10"
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="emergencyContact">
              <Form.Label>Emergency Contact</Form.Label>
              <Form.Control
                type="text"
                name="emergency_contact"
                value={formData.emergency_contact}
                onChange={handleChange}
                maxLength="10"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="yearOfPassing">
              <Form.Label>Year of Passing</Form.Label>
              <Form.Control
                type="text"
                name="year_of_passing"
                value={formData.year_of_passing}
                onChange={handleChange}
                maxLength="4"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="qualification">
              <Form.Label>Last Qualification</Form.Label>
              <Form.Control
                as="select"
                name="last_qualification"
                value={formData.last_qualification}
                onChange={handleChange}
                required
              >
                <option value="10th">10th</option>
                <option value="12th">12th</option>
                <option value="UG">UG</option>
                <option value="PG">PG</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {/* Address Section */}
        <h5 className="my-3">Address Information</h5>
        <Row>
          <Col md={6}>
            <Form.Group controlId="addressLine1">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                type="text"
                name="Address_Line1"
                value={formData.Address.Address_Line1}
                onChange={handleAddressChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="addressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                name="Address_Line2"
                value={formData.Address.Address_Line2}
                onChange={handleAddressChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="City"
                value={formData.Address.City}
                onChange={handleAddressChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                name="State"
                value={formData.Address.State}
                onChange={handleAddressChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="postalCode">
              <Form.Label>Postal Code</Form.Label>
              <Form.Control
                type="text"
                name="Postal_Code"
                value={formData.Address.Postal_Code}
                onChange={handleAddressChange}
                maxLength={6}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="policeStation">
              <Form.Label>Police Station</Form.Label>
              <Form.Control
                type="text"
                name="Police_Station"
                value={formData.Address.Police_Station}
                onChange={handleAddressChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="postOffice">
              <Form.Label>Post Office</Form.Label>
              <Form.Control
                type="text"
                name="Post_Office"
                value={formData.Address.Post_Office}
                onChange={handleAddressChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="district">
              <Form.Label>District</Form.Label>
              <Form.Control
                type="text"
                name="District"
                value={formData.Address.District}
                onChange={handleAddressChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        {/* File Uploads */}
        <h5 className="my-3">Upload Documents</h5>
        <Row>
          <Col md={6}>
            <Form.Group controlId="cv">
              <Form.Label>Upload CV</Form.Label>
              <Form.Control
                type="file"
                name="cv"
                onChange={handleFileChange}
                accept=".pdf, .doc, .docx"
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="aadhar">
              <Form.Label>Upload Aadhar</Form.Label>
              <Form.Control
                type="file"
                name="aadhar"
                onChange={handleFileChange}
                accept=".pdf, .jpg, .jpeg, .png"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="tenthAdmit">
              <Form.Label>Upload 10th Admit</Form.Label>
              <Form.Control
                type="file"
                name="tenth_admit"
                onChange={handleFileChange}
                accept=".pdf, .jpg, .jpeg, .png"
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="tenthMarksheet">
              <Form.Label>Upload 10th Marksheet</Form.Label>
              <Form.Control
                type="file"
                name="tenth_marksheet"
                onChange={handleFileChange}
                accept=".pdf, .jpg, .jpeg, .png"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="photo">
              <Form.Label>Upload Photo</Form.Label>
              <Form.Control
                type="file"
                name="photo"
                onChange={handleFileChange}
                accept=".jpg, .jpeg, .png"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          type="submit"
          variant="primary"
          disabled={loading}
          className="my-4 w-100"
        >
          {loading ? "Processing..." : "Submit Application"}
        </Button>
      </Form>
    </Container>
  );
};

export default StewardApplicationForm;
