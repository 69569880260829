import React, { useState } from 'react';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';

const JobSeekersForm = () => {
  const [formData, setFormData] = useState({
    name: '', // Added field for name
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    state: '',
    district: '',
    postOffice: '',
    policeStation: '',
    contact: '',
    email: '',
    adharNumber: '',
    passportNumber: '',
    employerName: '',
    employerContact: '',
    employerEmail: '',
    totalContractYears: '',
    employerAddressLine1: '',
    employerAddressLine2: '',
    employerPostalCode: '',
    employerCity: '',
    employerState: '',
    companyName: '',
    companyAuthorizedPerson: '',
    designation: '',
    salary: '',
    travelDetails: '',
    attachedPhoto: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      attachedPhoto: files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., sending data to an API or backend)
    console.log(formData);
  };

  return (
    <Container>
      <h2 className="my-4">Job Seekers Form</h2>
      <Form onSubmit={handleSubmit}>
        {/* Added Field Name at the top */}
        <Row>
          <Col md={6}>
            <Form.Group controlId="name">
              <Form.Label>Name *</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <h4>Address</h4>
        <Row>
          <Col md={6}>
            <Form.Group controlId="addressLine1">
              <Form.Label>Address Line 1 *</Form.Label>
              <Form.Control
                type="text"
                name="addressLine1"
                value={formData.addressLine1}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="addressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                name="addressLine2"
                value={formData.addressLine2}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="postalCode">
              <Form.Label>Postal / Zip Code *</Form.Label>
              <Form.Control
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="district">
              <Form.Label>District</Form.Label>
              <Form.Control
                type="text"
                name="district"
                value={formData.district}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="postOffice">
              <Form.Label>Post Office</Form.Label>
              <Form.Control
                type="text"
                name="postOffice"
                value={formData.postOffice}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="policeStation">
              <Form.Label>Police Station</Form.Label>
              <Form.Control
                type="text"
                name="policeStation"
                value={formData.policeStation}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group controlId="contact">
              <Form.Label>Contact *</Form.Label>
              <Form.Control
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="email">
              <Form.Label>Email *</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="adharNumber">
              <Form.Label>Adhar Number *</Form.Label>
              <Form.Control
                type="text"
                name="adharNumber"
                value={formData.adharNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="passportNumber">
              <Form.Label>Passport Number *</Form.Label>
              <Form.Control
                type="text"
                name="passportNumber"
                value={formData.passportNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="employerName">
              <Form.Label>Employer Name *</Form.Label>
              <Form.Control
                type="text"
                name="employerName"
                value={formData.employerName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="employerContact">
              <Form.Label>Employer Contact *</Form.Label>
              <Form.Control
                type="text"
                name="employerContact"
                value={formData.employerContact}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="employerEmail">
              <Form.Label>Employer Email *</Form.Label>
              <Form.Control
                type="email"
                name="employerEmail"
                value={formData.employerEmail}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="totalContractYears">
              <Form.Label>Total Contract Years *</Form.Label>
              <Form.Control
                type="number"
                name="totalContractYears"
                value={formData.totalContractYears}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <h4>Employer Address</h4>
        <Row>
          <Col md={6}>
            <Form.Group controlId="employerAddressLine1">
              <Form.Label>Employer Address Line 1 *</Form.Label>
              <Form.Control
                type="text"
                name="employerAddressLine1"
                value={formData.employerAddressLine1}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="employerAddressLine2">
              <Form.Label>Employer Address Line 2</Form.Label>
              <Form.Control
                type="text"
                name="employerAddressLine2"
                value={formData.employerAddressLine2}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group controlId="employerPostalCode">
              <Form.Label>Employer Postal / Zip Code *</Form.Label>
              <Form.Control
                type="text"
                name="employerPostalCode"
                value={formData.employerPostalCode}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="employerCity">
              <Form.Label>Employer City *</Form.Label>
              <Form.Control
                type="text"
                name="employerCity"
                value={formData.employerCity}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="employerState">
              <Form.Label>Employer State *</Form.Label>
              <Form.Control
                type="text"
                name="employerState"
                value={formData.employerState}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <h4>Company Information</h4>
        <Row>
          <Col md={4}>
            <Form.Group controlId="companyName">
              <Form.Label>Company Name *</Form.Label>
              <Form.Control
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="companyAuthorizedPerson">
              <Form.Label>Company Authorized Person *</Form.Label>
              <Form.Control
                type="text"
                name="companyAuthorizedPerson"
                value={formData.companyAuthorizedPerson}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="designation">
              <Form.Label>Designation *</Form.Label>
              <Form.Control
                type="text"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group controlId="salary">
              <Form.Label>Salary *</Form.Label>
              <Form.Control
                type="number"
                name="salary"
                value={formData.salary}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="travelDetails">
              <Form.Label>Travel Details</Form.Label>
              <Form.Control
                type="text"
                name="travelDetails"
                value={formData.travelDetails}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="attachedPhoto">
              <Form.Label>Attached Photo *</Form.Label>
              <Form.Control
                type="file"
                name="attachedPhoto"
                onChange={handleFileChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default JobSeekersForm;
