import React from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import BDMSidebar from "./BDMSidebar"; // Sidebar for BDM (specific to BDM role)

const BDMDashboard = () => {
  const token = localStorage.getItem("bdmtoken"); // Retrieve token from localStorage
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("bdmtoken"); // Remove token from local storage upon logout
    navigate("/"); // Redirect to BDM login page (change URL as needed)
  };

  // If no token, redirect to BDM login page
  if (!token) {
    console.log("No token found, redirecting to /bdm/login");
    return <Navigate to="/bdm/loged" />; // Adjusted redirect for BDM login
  }

  return (
    <Container fluid className="dashboard-container">
      <Row>
        <Col xs={12} md={3} lg={2} className="sidebar-container">
          <BDMSidebar /> {/* BDM Sidebar */}
        </Col>
        <Col xs={12} md={9} lg={10} className="main-content-container justify-content-center">
          <Button onClick={handleLogout} style={{ marginBottom: "10px" }}>
            Logout
          </Button>
          <Outlet /> {/* Renders the nested routes */}
        </Col>
      </Row>
    </Container>
  );
};

export default BDMDashboard;
