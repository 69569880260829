import React, { useState} from 'react';
import { Form, Button, FloatingLabel, Container, InputGroup } from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; // Axios for HTTP requests

const EnquiryForm = () => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    Name: '',              // Name field
    Mobile_No: '',         // Mobile_No field
    Email: '',             // Email field
    Message: '',           // Message field
    Pincode: '',           // Residential Pin Code
    Select_State: '',             // State field
    Select_City: ''               // City field
  });

  const [filteredCities, setFilteredCities] = useState([]);
  
  // State-to-city mapping with updated structure
  const stateCityData = [
    {
      name: 'Andhra Pradesh',
      cities: [
        "Amaravati", "Anantapur", "Chittoor", "Eluru", "Guntur", "Kadapa", "Kakinada", "Kurnool", "Machilipatnam", 
        "Nellore", "Ongole", "Rajahmundry", "Srikakulam", "Tirupati", "Visakhapatnam", "Vijayawada", "Vizianagaram",
        "Kothagudem", "Bapatla", "Narasaraopet", "Chilakaluripet", "Tadepalligudem", "Bobbili", "Nandyal", "Peddapalli",
        "Tanuku", "Rajampet", "Mandapeta", "Macherla", "Palnadu", "Chirala", "Jangareddygudem", "Malkangiri", "Kavali",
        "Proddatur", "Pithapuram", "Anakapalle", "Madanapalle", "Chittoor"
      ]
    },
    {
      name: 'Arunachal Pradesh',
      cities: [
        "Aalo", "Anini", "Basar", "Changlang", "Daporijo", "Itanagar", "Jairampur", "Jolmuk", "Khonsa", "Lohit", 
        "Namsai", "Pasighat", "Raga", "Roing", "Seppa", "Tawang", "Tezu", "Yazali", "Bomdila", "Miao",
        "Pangin", "Ziro", "Nirjuli", "Dirang", "Mahadevpur", "Siyum", "Ruksin", "Kaying", "Longding", "Tali",
        "Dibang Valley", "Lakhimpur", "Bordumsa", "Meka", "Namsai", "Rangfra", "Yingkiong", "Tawang", "Khellong",
        "Baliyang"
      ]
    },
    {
      name: 'Assam',
      cities: [
        "Baksa", "Barpeta", "Bongaigaon", "Dhubri", "Dibrugarh", "Guwahati", "Hojai/Lumding",
        "Lakhimpur", "Nalbari", "Patshala", "Silchar", "Sivsagar", "Tezpur", "Jorhat"
      ]
    },
    {
      name: 'Bihar',
      cities: [
        "Ara", "Aurangabad", "Bagaha", "Barh", "Begusarai", "Bettiah", "Bhagalpur", "Bihar Sharif", "Buxar", "Chandanpur", 
        "Chhapra", "Darbhanga", "Dalsinghsarai", "Dumraon", "Fatuha", "Gaya", "Hajipur", "Jamui", "Jhanjharpur", "Katihar", 
        "Khagaria", "Kishanganj", "Lakhisarai", "Madhobpur", "Madhubani", "Munger", "Motihari", "Nalanda", "Nawada", "Patna", 
        "Patna City", "Purnia", "Raxaul", "Rourkela", "Samastipur", "Sasaram", "Sheikhpura", "Siwan", "Vaishali"
      ]
    },
    {
      name: 'Chhattisgarh',
      cities: [
        "Bilaspur", "Durg", "Raipur", "Korba", "Bhilai", "Rajnandgaon", "Raigarh", "Jagdalpur", "Ambikapur", "Kanker", 
        "Kabirdham", "Rajnandgaon", "Jashpur", "Balod", "Dongargarh", "Pendra", "Dhamtari", "Bemetara", "Mungeli", "Mahasamund", 
        "Janjgir", "Champa", "Bhilai Nagar", "Chirmiri", "Pathalgaon", "Sakti", "Gariaband", "Gadchiroli", "Surguja", "Bilaspur City", 
        "Kawardha", "Pali", "Pandariya", "Lakhanpur", "Koriya", "Kusmunda", "Shivpur", "Tilda", "Dondilohara", "Bilhari", "Pendra Road"
      ]
    },
    {
      name: 'Goa',
      cities: [
        "Acora", "Agonda", "Alorna", "Anjuna", "Assagao", "Benaulim", "Betalbatim", "Betul", "Candolim", "Cortalim", 
        "Dona Paula", "Farmagudi", "Fatorda", "Galgibaga", "Grande Island", "Guirim", "Margaon", "Mapusa", "Miramar", 
        "Morjim", "Navelim", "Panjim", "Pilar", "Raia", "Ribandar", "Safa", "Sanguem", "Seraulim", "Shiroda", 
        "Sirvoi", "Soi", "Taleigao", "Tivim", "Verna", "Vasco da Gama", "Zambaulim", "Chandor", "Cortalim", "Ponda", 
        "Polem", "Quepem"
      ]
    },
    {
      name: 'Gujarat',
      cities: [
        "Ahmedabad", "Amreli", "Anand", "Bharuch", "Bhavnagar", "Bhuj", "Dahod", "Gandhinagar", "Gir Somnath", "Himatnagar",
        "Junagadh", "Kheda", "Mahesana", "Nadiad", "Navsari", "Narmada", "Patan", "Porbandar", "Rajkot", "Surat",
        "Sabar Kantha", "Valsad", "Vapi", "Vadodara", "Vallabh Vidyanagar", "Daman", "Veraval", "Bhadran", "Wadhwan",
        "Modasa", "Vijapur", "Unjha", "Surendranagar", "Ankleshwar", "Dholka", "Gandhidham", "Gandhinagar", "Palanpur",
        "Bardoli", "Ranavav", "Mahudha"
      ]
    },
    {
      name: 'Haryana',
      cities: [
        "Ambala", "Bhiwani", "Chandigarh", "Faridabad", "Fatehabad", "Gurugram", "Hisar", "Jhajjar", "Jind", "Kaithal", 
        "Karnal", "Kurukshetra", "Mahendragarh", "Mewat", "Palwal", "Panchkula", "Panipat", "Rewari", "Rohtak", "Sonepat", 
        "Sirsa", "Thanesar", "Bawal", "Hansi", "Narnaul", "Nuh", "Pehowa", "Pinjore", "Tosham", "Bapora", 
        "Charkhi Dadri", "Kalka", "Hodal", "Karnal City", "Mandi Dabwali", "Tigaon", "Barwala", "Gohana", "Dhandhuka", 
        "Farrukhnagar", "Bahadurgarh", "Pataudi"
      ]
    },
    {
      name: 'Himachal Pradesh',
      cities: [
        "Bilaspur", "Chamba", "Dharamshala", "Hamirpur", "Kullu", "Kangra", "Mandi", "Nahan", "Solan", "Una", 
        "Kasauli", "Palampur", "Renuka", "Nurpur", "Jwalamukhi", "Chintpurni", "Shimla", "Poanta Sahib", "Arki", 
        "Bharwain", "Jaswan", "Ghumarwin", "Nadaun", "Sundernagar", "Churah", "Banikhet", "Chail", "Jai Singh Puri", 
        "Chopal", "Sarkaghat", "Keylong", "Narkanda", "Kullu Valley", "Manali", "Chhatrari", "Baddi", "Nalagarh", 
        "Gaggal", "Baggi", "Kotla", "Padhar"
      ]
    },
    {
      name: "Jharkhand",
      cities: [
        "Adityapur", "Bokaro", "Dhanbad", "Deoghar", "Hazaribagh", "Jamshedpur", "Ranchi", "Giridih", "Jharia", "Dumka", 
        "Chaibasa", "Lohardaga", "Ramgarh", "Pakur", "Saraikela", "Koderma", "Godda", "Khunti", "Simdega", "Latehar", 
        "Palamu", "Garhwa", "Bermo", "Chandil", "Hazaribagh", "Patamda", "Jamtara", "Raghunathganj", "Madhupur", 
        "Ghatshila", "Ranchi City", "Chandrapura", "Kokpar", "Mihijam", "Bundu", "Piska", "Gumla", "Tundi", 
        "Barkatha", "Jamtara Town", "Madhupur", "Tirla"
      ]
    },
    {
      name: "Karnataka",
      cities: [
        "Bagalkot", "Bangalore", "Belgaum", "Bellary", "Bidar", "Bijapur", "Chikkamagaluru", "Chitradurga", "Davangere", 
        "Dharwad", "Gadag", "Hassan", "Hubli", "Hunsur", "Kalaburagi", "Karwar", "Kolar", "Koppal", "Mysore", "Mangalore", 
        "Raichur", "Ramanagaram", "Shimoga", "Tumkur", "Udupi", "Bagalkot", "Channarayapatna", "Hospet", "Karkala", "Lingsugur", 
        "Mandya", "Puttur", "Sira", "Sakleshpur", "Yadgir", "Channarayapatna", "Bantwal", "Gokak", "Athani", "Sirsi"
      ]
    },
    {
      name: "Kerala",
      cities: [
        "Alappuzha", "Angamaly", "Kannur", "Kasaragod", "Kochi", "Kollam", "Kottayam", "Kozhikode", "Malappuram", 
        "Mavelikara", "Meenchanda", "Palakkad", "Payyannur", "Perumbavoor", "Punalur", "Sreekariyam", "Thrissur", 
        "Thiruvananthapuram", "Vadakara", "Varkala", "Wayanad", "Irinjalakuda", "Kochi", "Ponnani", "Kodungallur", 
        "Changanassery", "Chalakudy", "Ernakulam", "Thrippunithura", "Ottappalam", "Palai", "Manjeri", "Vypin", 
        "Edappally", "Kunnamkulam", "Kayani", "Cherthala", "Vatakara", "Karunagappally", "Aluva"
      ]
    },
    {
      name: "Madhya Pradesh",
      cities: [
        "Bhopal", "Indore", "Gwalior", "Jabalpur", "Ujjain", "Sagar", "Rewa", "Dewas", "Shivpuri", "Chhindwara", 
        "Satna", "Hoshangabad", "Ratlam", "Mandsaur", "Sehore", "Vidisha", "Khargone", "Balaghat", "Khandwa", "Shahdol", 
        "Neemuch", "Guna", "Bhind", "Morena", "Tikamgarh", "Damoh", "Seoni", "Jhabua", "Panna", "Betul", 
        "Anuppur", "Chhatarpur", "Shivpuri", "Mandla", "Harda", "Shujalpur", "Rajgarh", "Barwani", "Ashok Nagar", 
        "Buxwaha", "Maihar"
      ]
    },
    {
      name: "Maharashtra",
      cities: [
        "Ahmednagar", "Akola", "Alibag", "Amravati", "Aurangabad", "Bhandara", "Bhiwandi", "Buldhana", "Chandrapur", 
        "Dhule", "Gadchiroli", "Ichalkaranji", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Malegaon", "Malkapur", "Mumbai", 
        "Nagpur", "Nanded", "Nashik", "Navi Mumbai", "Parbhani", "Pimpri-Chinchwad", "Pune", "Raigad", "Ratnagiri", 
        "Sangli", "Satara", "Solapur", "Thane", "Ulhasnagar", "Vasai", "Vijayapura", "Wardha", "Yavatmal", 
        "Kalyan", "Karad", "Bharuch", "Shirdi"
      ]
    },
    {
      name: "Manipur",
      cities: [
        "Bishnupur", "Chandel", "Churachandpur", "Imphal", "Jiribam", "Kakching", "Kangpokpi", "Kamjong", "Kangla", "Kumbi", 
        "Lamlai", "Lamshang", "Leimatak", "Longe", "Moirang", "Nambol", "Ningthoukhong", "Pallel", "Phungyar", "Senapati", 
        "Shirui", "Tengnoupal", "Thoubal", "Ukhrul", "Wangjing", "Wahengbam", "Koirengei", "Khonghampat", "Keirao", 
        "Lalambung", "Yairipok", "Tamelong", "Tengkong", "Thangjing", "Kakching Khunou", "Mongjam", "Lamlai Bazar", 
        "Wangbal", "Tera", "Singjamei", "Khangabok"
      ]
    },
    {
      name: "Meghalaya",
      cities: [
        "Barapani", "Bharati", "Bismillah", "Cherrapunji", "Dalu", "Damra", "Gaulrith", "Garo Hills", "Jowai", "Khliehriat",
        "Kharang", "Laitlyngkot", "Laban", "Mawlai", "Mawphlang", "Mawkyrwat", "Mendipathar", "Mawkyrwat", "Mairang", "Nongstoin",
        "Nongpoh", "Nongkhrah", "Nongbah", "Pynursla", "Pahamsuk", "Resubelpara", "Shillong", "Sohra", "Sohphoh", "Tura",
        "Umling", "Umsning", "West Khasi Hills", "West Garo Hills", "Pynursla", "Tura", "Mairang", "Khliehriat", "Jowai",
        "Mendipathar"
      ]
    },
    {
      name: "Mizoram",
      cities: [
        "Aizawl", "Bairabi", "Champhai", "Hnahthial", "Lunglei", "Kolasib", "Lawngtlai", "Mamit", "Serchhip", "Siaha",
        "Zokhawthar", "Saitual", "Kawnpui", "Tuipang", "Tlabung", "Kolasib", "Thenzawl", "Vairengte", "Hnahthial",
        "Zawlnuam", "Mualthuam", "N. Vanlaiphai", "Darlawn", "Bualpui", "Lungchhuan", "Champhai", "Rungpui", "Sailo",
        "Ngopa", "Sakahchhawng", "Bualpui", "Khawbung", "Kawnpui", "Zokhawthar", "Sairang", "Lunglei Town", "Mamit Town",
        "Vairengte", "Thenzawl", "Hnahthial"
      ]
    },
    {
      name: "Nagaland",
      cities: [
        "Dimapur", "Kohima", "Mokokchung", "Tuensang", "Wokha", "Zunheboto", "Phek", "Mon", "Longleng", "Kiphire",
        "Bokajan", "Mangkolemba", "Chumoukedima", "Tseminyu", "Akuluto", "Medziphema", "Satoi", "Peren", "Phokhungri",
        "Changtongya", "Liphanyan", "Kohima Town", "Mokokchung Town", "Wokha Town", "Zunheboto Town", "Tizit", "Pughoboto",
        "Aghunato", "Chakabama", "Mokokchung Town", "Medziphema Town"
      ]
    },
    {
      name: "Odisha",
      cities: [
        "Bhubaneswar", "Cuttack", "Berhampur", "Rourkela", "Bolangir", "Balasore", "Kendrapara", "Sambalpur", "Koraput", 
        "Angul", "Khurda", "Dhenkanal", "Jeypore", "Rayagada", "Bargarh", "Bhadrak", "Puri", "Nayagarh", "Khurda", "Jagatsinghpur",
        "Ganjam", "Kendujhar", "Nabarangpur", "Boudh", "Sundargarh", "Deogarh", "Malkangiri", "Kalahandi", "Nuapada", "Gajapati", 
        "Balesore", "Nayagarh", "Boudh", "Dhenkanal", "Rairakhol", "Bargarh", "Cuttack"
      ]
    },
    {
      name: "Punjab",
      cities: [
        "Amritsar", "Bathinda", "Chandigarh", "Jalandhar", "Ludhiana", "Patiala", "Ferozepur", "Hoshiarpur", "Pathankot", 
        "Rajpura", "Mohali", "Moga", "Muktsar", "Rupnagar", "Sangrur", "Faridkot", "Fatehgarh Sahib", "Kapurthala", "Nawanshahr",
        "Mansa", "Barnala", "Gurdaspur", "Tarn Taran", "Fazilka", "Zira", "Khamano", "Banga", "Gidderbaha", "Fattuwal"
      ]
    },
    {
      name: "Rajasthan",
      cities: [
        "Jaipur", "Jodhpur", "Udaipur", "Kota", "Ajmer", "Bikaner", "Alwar", "Sikar", "Bhilwara", "Chittorgarh", "Pali", "Barmer",
        "Jaisalmer", "Tonk", "Rajsamand", "Dungarpur", "Jhunjhunu", "Bundi", "Nagaur", "Sirohi", "Ratangarh", "Sri Ganganagar", 
        "Hanumangarh", "Churu", "Banswara", "Jhalawar", "Dholpur", "Karauli", "Jalore", "Baran", "Sawai Madhopur", "Sirohi", 
        "Jaitaran", "Makrana", "Raghunathganj", "Merta City", "Lohawat"
      ]
    },
    {
      name: "Sikkim",
      cities: [
        "Gangtok", "Namchi", "Mangan", "Jorethang", "Rangpo", "Singtam", "Tadong", "Lachen", "Chungthang", "Pakyong", 
        "Chakung", "Yangang", "Temi", "Bermiok", "Rangang", "Rabong", "Rongli", "Barfung", "Chopthang", "Soreng", "Yuksom"
      ]
    },
    {
      name: "Tamil Nadu",
      cities: [
        "Chennai", "Coimbatore", "Madurai", "Tiruchirappalli", "Salem", "Erode", "Vellore", "Tirunelveli", "Thanjavur", 
        "Dindigul", "Kanchipuram", "Thoothukudi", "Cuddalore", "Pudukkottai", "Karur", "Nagercoil", "Chidambaram", 
        "Nagapattinam", "Sivakasi", "Pollachi", "Kumbakonam", "Ramanathapuram", "Villupuram", "Tiruvannamalai", 
        "Rishivandiyam", "Tirupur", "Kanyakumari", "Tiruchendur", "Sriperumbudur", "Kanchipuram", "Virudhunagar"
      ]
    },
    {
      name: "Telangana",
      cities: [
        "Hyderabad", "Warangal", "Khammam", "Nizamabad", "Karimnagar", "Nalgonda", "Mahabubnagar", "Rangareddy", "Suryapet",
        "Miryalaguda", "Adilabad", "Medak", "Sangareddy", "Nagarkurnool", "Jagtial", "Sircilla", "Peddapalli", "Jangaon", 
        "Mulugu", "Bhupalpally", "Vikarabad", "Kamareddy", "Yadadri Bhuvanagiri", "Wanaparthy", "Kothagudem", "Nirmal", 
        "Peddapalli", "Nizamsagar", "Vemulawada", "Malkajgiri", "Medchal", "Peddapalli", "Bodhan", "Siddipet"
      ]
    },
    {
      name: "Tripura",
      cities: [
        "Agartala", "Ambassa", "Belonia", "Dharmanagar", "Kailashahar", "Khowai", "Sonamura", "Udaipur", "Kanchanpur", 
        "Ranirbazar", "Sadar", "Jirania", "Mohanpur", "Barjala", "Bishalgarh", "Sabroom", "Bagbassa", "Pratapgarh", 
        "Bishramganj", "Matinagar", "Sakuntala", "Agartala", "Takarjala", "Jampuijala", "Bagagacha", "Kumarghat", "Amarpur"
      ]
    },
    {
      name: "Uttarakhand",
      cities: [
        "Dehradun", "Haridwar", "Nainital", "Rishikesh", "Roorkee", "Haldwani", "Udham Singh Nagar", "Almora", "Pithoragarh",
        "Ramnagar", "Tehri", "Rudrapur", "Nanakmatta", "Rishikesh", "Bijnor", "Kashipur", "Jaspur", "Srinagar", 
        "Kichha", "Khatima", "Champawat", "Bageshwar", "Mukteshwar", "Pauri", "Karanprayag", "Sundarnagar", "Kedarnath"
      ]
    },
    {
      name: "Uttar Pradesh",
      cities: [
        "Agra", "Aligarh", "Allahabad", "Bareilly", "Ghaziabad", "Kanpur", "Lucknow", "Mathura", "Meerut", "Moradabad", 
        "Varanasi", "Ayodhya", "Firozabad", "Jhansi", "Sitapur", "Muzaffarnagar", "Saharanpur", "Gorakhpur", "Shahjahanpur",
        "Raebareli", "Mau", "Basti", "Ballia", "Rampur", "Azamgarh", "Chitrakoot", "Unnao", "Badaun", "Pilibhit", 
        "Bahraich", "Bijnor", "Amroha", "Etawah", "Etah", "Chandpur", "Prayagraj", "Fatehpur", "Mathura", "Sambhal"
      ]
    },
    {
      name: "West Bengal",
      cities: [
        "Kolkata", "Siliguri", "Asansol", "Durgapur", "Howrah", "Kalyani", "Bardhaman", "Malda", "Jalpaiguri", "Kolar", 
        "Bangaon", "Chandannagar", "Haldia", "Burdwan", "Purulia", "Bankura", "Murarai", "Krishnanagar", "Kharagpur", 
        "Berhampore", "Santiniketan", "Medinipur", "Raiganj", "Sainthia", "Cooch Behar", "Kurseong", "Shibpur", "Sujapur", 
        "Raghunathganj", "Shibpur", "Jadavpur", "Madanpur", "Bansberia", "Salt Lake"
      ]
    }
  ];

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

// Handle state change and filter cities based on selected state
const handleStateChange = (e) => {
  const selectedState = e.target.value;
  setFormData((prevData) => ({
    ...prevData,
    Select_State: selectedState,
    Select_City: '' // Reset city when state changes
  }));
    
    // Filter cities based on selected state
    const stateObj = stateCityData.find(state => state.name === selectedState);
    setFilteredCities(stateObj ? stateObj.cities : []);
  };

  // Handle city change from either the input field or the datalist
  const handleCityChange = (e) => {
    setFormData({
      ...formData,
      Select_City: e.target.value
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form default behavior (reloading the page)
    const form = event.currentTarget;

    // Validate form fields
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true); // Set form as validated

    // Prepare all the form data to send
    const dataToSend = {
      ...formData,         // Include all form data (Name, Mobile_No, Email, Message, Residential_Pin_Code)
    };

    try {
      // Sending form data using axios 
      const response = await axios.post("https://api.ocupiiehospitalityacademy.com/api/enquiries", dataToSend);
      
      if (response.status === 201) {
        // Handle success response
        alert('Form submitted successfully!');
        
        // Reset form fields
        setFormData({
          Name: '',
          Mobile_No: '',
          Email: '',
          Message: '',
          Pincode: '',
          Select_State: '', // Reset Select_State field
          Select_City: '' // Reset Select_City field
        });
        setFilteredCities([]);
      } else {
        // Handle non-200 responses
        alert('Something went wrong. Please try again.');
      }
    } catch (error) {
      // Log and handle error
      console.error('Error submitting the form:', error.response ? error.response.data : error.message);
      alert('Failed to submit the form. Please try again later.');
    }
  };

  return (
    <Container className='p-0 m-0'>
       <Form noValidate validated={validated} onSubmit={handleSubmit}>
     
      {/* Name Field */}
      <Form.Group controlId="name" className="mb-1 m-0 p-0">
        <FloatingLabel label="Full Name">
          <Form.Control
            type="text"
            name="Name" // Match backend field name
            value={formData.Name}
            onChange={handleInputChange}
            placeholder="Full Name"
            maxLength={255} // Corresponds to the backend rule
            required
          />
          <Form.Control.Feedback type="invalid">Please provide your full name.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* Mobile Number Field */}
      <Form.Group controlId="mobile" className="mb-1">
        <FloatingLabel label="Phone Number">
          <Form.Control
            type="tel"
            name="Mobile_No" // Match backend field name
            value={formData.Mobile_No}
            onChange={handleInputChange}
            placeholder="Phone Number"
            pattern="[0-9]{10,12}" // Digits between 10-12
            maxLength={12}
            required
          />
          <Form.Control.Feedback type="invalid">Please provide a valid phone number (10-12 digits).</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* Email Field */}
      <Form.Group controlId="email" className="mb-1">
        <FloatingLabel label="Email Address">
          <Form.Control
            type="email"
            name="Email" // Match backend field name
            value={formData.Email}
            onChange={handleInputChange}
            placeholder="Email Address"
            pattern="[\-a-zA-Z0-9~!$%^&amp;*_=+\}\{'?]+(\.[\-a-zA-Z0-9~!$%^&amp;*_=+\}\{'?]+)*@[a-zA-Z0-9_][\-a-zA-Z0-9_]*(\.[\-a-zA-Z0-9_]+)*\.[cC][oO][mM](:[0-9]{1,5})?"
            required
          />
          <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* Residential Pin Code Field */}
      <Form.Group controlId="pinCode" className="mb-1">
        <FloatingLabel label="Residential Pin Code">
          <Form.Control
            type="text"
            name="Pincode" // Match backend field name
            value={formData.Pincode}
            onChange={handleInputChange}
            placeholder="Pin Code"
            pattern="[0-9]{6}" // Assuming a 6-digit pin code
            maxLength={6}
            required
          />
          <Form.Control.Feedback type="invalid">Please provide a valid pin code (6 digits).</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* State Selection with FloatingLabel */}
      <Form.Group className="mb-3">
        <FloatingLabel label="Select State">
          <Form.Control
            as="select"
            name="state"
            value={formData.state}
            onChange={handleStateChange}
            required
          >
            <option value="">Select State</option>
            {stateCityData.map((state) => (
              <option key={state.name} value={state.name}>
                {state.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">Please select a state.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* City Selection with FloatingLabel */}
      <Form.Group className="mb-3">
        <FloatingLabel label="">
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Enter city name"
              name="city"
              value={formData.city}
              onChange={handleCityChange}
              list="cities"
              required
            />
            <datalist id="cities">
              {filteredCities.map((city, index) => (
                <option key={index} value={city} />
              ))}
            </datalist>
          </InputGroup>
          <Form.Control.Feedback type="invalid">Please select or enter a city.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* Message Field */}
      <Form.Group controlId="message" className="mb-1">
        <FloatingLabel label="Your Message (max 500 char.)">
          <Form.Control
            as="textarea"
            name="Message" // Match backend field name
            value={formData.Message}
            onChange={handleInputChange}
            placeholder="Your Message"
            required
          />
          <Form.Control.Feedback type="invalid">Please provide a message (max 500 char.).</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* Submit Button */}
      <div className="d-flex justify-content-end align-items-end">
        <Button type="submit">Submit</Button>
      </div>
    </Form>
  </Container>
  );
};

export default EnquiryForm;
