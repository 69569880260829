import React, { useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const StudentLogin = () => {
  const [enrollmentId, setEnrollmentId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false); // To handle form validation
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    // Form validation check
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return; // If the form is invalid, don't proceed with the login request
    }

    setValidated(true); // Set validated state after form check

    setError(""); // Reset error message before attempting login

    // Proceed with login if form is valid
    const email = enrollmentId;
    axios.post("http://127.0.0.1:8000/api/login", { email, password })
      .then((response) => {
        // On successful login, store token and navigate to the admin dashboard
        localStorage.setItem("studenttoken", response.data.token);
        navigate("/student");
      })
      .catch((error) => {
        console.error("Error object:", error); // Log the whole error object for debugging

        if (error.response) {
          // If the response exists, check its structure
          const { data } = error.response;
          console.log("Error response data:", data); // Log the response data

          if (data?.error) {
            // If message exists, show it
            setError(data.error);
          } 
          else if (data?.errors) {
            // If validation errors exist, show them
            const fieldErrors = Object.values(data.errors).flat().join(" ");
            setError(fieldErrors);
          } 
          else {
            // If no specific error message or validation, show a generic error
            setError("Login failed: Unknown error");
          }
        } 
        else if (error.request) {
          // If no response was received
          setError("Login failed: No response from the server.");
        } else {
          // Errors in setting up the request
          setError("Login failed: " + error.message);
        }
      });
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.loginForm}>
        <h2 style={styles.title}>Student Login</h2>
        {error && <p style={{ color: "red" }}>{error}</p>}
        
        <form noValidate validated={validated} onSubmit={handleLogin}>
          <div style={styles.inputGroup}>
            <label>Enrollment ID</label>
            <input
              type="text" // Using text since enrollment IDs are usually alphanumeric
              value={enrollmentId}
              onChange={(e) => setEnrollmentId(e.target.value)}
              style={styles.inputss}
              required
              isInvalid={validated && !enrollmentId} // Show validation error
            />
             {validated && !enrollmentId && <div style={{ color: "red" }}>Enrollment Id is required</div>}
          </div>
          <div style={styles.inputGroup}>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.inputss}
              required
              isInvalid={validated && !password} // Show validation error
            />
             {validated && !password && <div style={{ color: "red" }}>Password is required</div>}
          </div>
          <button type="submit" style={styles.loginButton}>Login</button>
        </form>

        {/* Forgot Password Link */}
        <div style={styles.forgotPasswordContainer}>
          <Link to="/forgot-password" style={styles.forgotPasswordLink}>Forgot Password?</Link>
        </div>
      </div>
    </div>
  );
}

const styles = {
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundImage: `url('/images/login.png')`,
    backgroundSize: 'cover', // Ensures the image covers the entire container
    backgroundPosition: 'center', // Centers the image
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  },
  loginForm: {
    backgroundColor: '#e4e5e4',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '300px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  inputGroup: {
    marginBottom: '20px',
  },
  inputss: {
    width: '100%',
    padding: '8px',
    marginTop: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  loginButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#4267B2',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
  },
  forgotPasswordContainer: {
    marginTop: '10px',
    textAlign: 'center',
  },
  forgotPasswordLink: {
    fontSize: '14px',
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default StudentLogin;
