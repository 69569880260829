import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Slide from './Slider/Slide'
import Buttons from './Button/Buttons';
import Hospitality from './Hospitality/Hospitality';
import CoursesHome from './CoursesHome/CoursesHome';
import MissionVision from './MissionAndVision/MisionVision';
import WhyChoose from './WhyChooseUs/WhyChoose';
import VectorImage from './VectorImage/VectorImage';
import Onlinelearn from './OnlineLearn/Onlinelearn';
import WhatsAppButton from './WhatsAppButton/WhatsAppButton';
import WorldWidePlacement from '../WorldWidePlacement/WorldWidePlacement';
import WhyLearnWithOcupiie from './WhyLearn/WhyLearnWithOcupiie';
import LoginButton from './Login/LoginButton';
import EnquiryComponent from './StudentEnqiry/EnquiryComponent';
import ParentComponent from './StewardApplicationForm/ParentComponent';

// import Checkout from '../Checkout';
// import PaymentComponent from '../PaymentComponent/PaymentComponent';

function Home() {
  return (
    <>
    
         <Slide />  

        <Buttons />
        
        <Hospitality />
            
        <VectorImage />
    
      <EnquiryComponent/>
        <Onlinelearn/>
 
        <CoursesHome />

        <WhyLearnWithOcupiie/>
      
      
        <MissionVision/>
        <WorldWidePlacement/>
     
        <WhyChoose/>
       
        <WhatsAppButton/>
        <ParentComponent/>    
        <LoginButton/>
        
      
    </>
  )
}

export default Home