import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios
import styles from './BDMProfile.module.css'; // Import the CSS module

function BDMProfile() {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("bdmtoken");
    axios
      .get("http://127.0.0.1:8000/api/BDM/profiles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProfile(response.data.BDM_Profiles);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const statusClass = profile.Status ? (profile.Status.toLowerCase() === 'active' ? styles.active : styles.inactive) : '';

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>BDM Profile</h1>
      <div className={styles.profileInfo}>
        <div className={styles.divdiv}>
          <label className={styles.label}>Name:</label>
          <div className={styles.value}>{profile.Name}</div>
        </div>

        <div>
          <label className={styles.label}>Address:</label>
          <div className={styles.value}>{profile.Profile_Address}</div>
        </div>

        <div>
          <label className={styles.label}>Contact:</label>
          <div className={styles.value}>{profile.Contact}</div>
        </div>

        <div>
          <label className={styles.label}>Email:</label>
          <div className={styles.value}>{profile.Email}</div>
        </div>

        <div>
          <label className={styles.label}>Joining Date:</label>
          <div className={styles.value}>{profile.Joining_Date}</div>
        </div>

        <div>
          <label className={styles.label}>Status:</label>
          <div className={`${styles.status} ${statusClass}`}>
            {profile.Status || 'No Status Available'}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BDMProfile;
