import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CourseDetails = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Example API URL
    const apiUrl = "http://127.0.0.1:8000/api/student-details";

    // Making an API call using axios with .then() and .catch()
    axios.get(apiUrl)
      .then(response => {
        // On success, set the fetched data into the state
        setData(response.data.course_details); // Adjusted to get 'course_details' from response
        setLoading(false); // Once the data is loaded, stop the loading state
      })
      .catch(error => {
        // On error, set the error message into the state
        setError(error.message);
        setLoading(false); // Stop loading state
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={styles.container}>
      <h2>Course Details</h2>
      <div style={styles.detailsContainer}>
        <div style={styles.detailItem}>
          <strong>Course Name:</strong> {data.Course_Name}
        </div>
        <div style={styles.detailItem}>
          <strong>Course Type:</strong> {data.Course_Type}
        </div>
        <div style={styles.detailItem}>
          <strong>Date Of Enrollment:</strong> {data.Date_Of_Enrollment}
        </div>
        <div style={styles.detailItem}>
          <strong>Payment Status:</strong> {data.Payment_Status}
        </div>
      </div>
    </div>
  );
};

// Sample styles
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  detailsContainer: {
    marginTop: '10px',
  },
  detailItem: {
    marginBottom: '8px',
    fontSize: '16px',
  },
};

export default CourseDetails;
