import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Font,
} from "@react-pdf/renderer";

// Define styles for the PDF document

Font.register({
  family: "Collingar",
  src: "/fonts/Collingar 400.otf", // Path to the font in the public folder
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  headerBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: 0,
    marginBottom: 10,
    paddingBottom: 20,
    backgroundColor: "#f8f4ec",
  },
  logoBox: {
    width: "35%", // 50% of the row for logo
    // Bottom border for logo
    borderRight: "0.5px solid #000", // Right border for logo
    borderBottom: "0.5px solid #000",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: 130, // Adjust the size of the logo
    height: 130,
  },
  invoiceBox: {
    fontFamily: "Collingar",
    width: "65%", // 50% of the row for invoice text
    borderTop: "0.5px solid #000", // Top border for invoice
    borderBottom: "0.5px solid #000",
    marginTop: 10,
    paddingTop: 30,
    paddingBottom: 23.3,
    alignItems: "center",
    textAlign: "center",
  },
  invoiceText: {
    fontSize: 60,
    fontWeight: "bold",
  },

  // company

  companyAddress: {
    flexDirection: "row", // To align items horizontally
    justifyContent: "space-between", // Space out the columns evenly
    width: "100%", // Full width of the container
    marginBottom: 10, // Adjust the margin as needed
    border: "1 solid #000",
  },
  column1: {
    flex: 1, // Ensures each column takes equal space
    justifyContent: "center", // Vertically center text within the column
    alignItems: "center", // Horizontally center the text
    padding: 10, // Padding around the text
    borderRight: "1 solid #000",
  },
  column2: {
    flex: 1, // Ensures each column takes equal space
    justifyContent: "center", // Vertically center text within the column
    alignItems: "center", // Horizontally center the text
    padding: 10, // Padding around the text
    borderRight: "1 solid #000",
  },
  column3: {
    flex: 1, // Ensures each column takes equal space
    justifyContent: "center", // Vertically center text within the column
    alignItems: "center", // Horizontally center the text
    padding: 10, // Padding around the text
  },
  companyAddressChiled1: {
    fontSize: 10, // Adjust font size as needed
    textAlign: "center", // Center text horizontally
  },
  companyAddressChiled2: {
    fontSize: 10, // Adjust font size as needed
    textAlign: "center", // Center text horizontally
  },
  companyAddressChiled3: {
    fontSize: 10, // Adjust font size as needed
    textAlign: "center", // Center text horizontally
  },

  // Billing and Address
  billingPageP: {
    border: "1 solid #000",
  },
  billingAddress: {
    flexDirection: "row", // To align the billing information in a row
    justifyContent: "space-between", // To distribute space between columns
    width: "100%", // Full width of the container
    fontSize: 12,
    borderBottom: "1 solid #000",
    backgroundColor: "#f8f4ec",
  },
  billedTo: {
    width: "70%", // Billed To takes 70% width
    textAlign: "left", // Align text to the left
    borderRight: "1 solid #000",
    padding: 10,
    fontFamily: "Collingar",
  },
  invoiceNo: {
    width: "30%", // Invoice No takes 30% width
    textAlign: "left", // Align text to the left
    padding: 10,
    fontFamily: "Collingar",
  },
  customerInfo: {
    flexDirection: "row", // Aligning customer information in a row
    justifyContent: "space-between", // Space out the columns evenly
    width: "100%", // Full width of the container
  },
  leftColumn: {
    width: "70%", // Customer's Name and Address take 70%
    textAlign: "left", // Align text to the left
    borderRight: "1 solid #000",
  },
  rightColumn: {
    width: "30%", // Data like invoice number and date take 30%
    textAlign: "left", // Align text to the left
  },
  customerText: {
    fontSize: 12, // Adjust font size as needed
    textAlign: "left", // Left-aligned text
    padding: 10,
  },
  // Adjustments for child text in customer address
  customerChildText1: {
    fontSize: 10, // Adjust font size as needed
    textAlign: "left", // Left-aligned text
    padding: 10,
    // backgroundColor: "#9acd32",
    borderBottom: "1 solid #000",
  },
  customerChildText2: {
    fontSize: 10, // Adjust font size as needed
    textAlign: "left", // Left-aligned text
    padding: 10,
    backgroundColor: "#f8f4ec",
    borderBottom: "1 solid #000",
    fontFamily: "Collingar",
  },
  customerChildText3: {
    fontSize: 10, // Adjust font size as needed
    textAlign: "left", // Left-aligned text
    padding: 10,
  },

  // Four-column layout for Description, Rate, Hours, and Amount
  descriptionpage: {
    border: "1 solid #000",
    borderBottom: "none",
    marginTop: 30,
  },

  columnsContainer: {
    flexDirection: "row", // Align items horizontally in a row
    justifyContent: "space-between", // Distribute space evenly between columns
    width: "100%", // Full width of the container
  },

  // Each column (Description, Rate, Hours, Amount) takes equal width
  columndes: {
    flex: 1, // Makes each column take equal width
    textAlign: "left", // Align text to the left
    padding: 10, // Padding around the text
    borderRight: "1 solid #000", // Border right for each column
    borderBottom: "1 solid #000",
    backgroundColor: "#f8f4ec",
  },

  columndes1: {
    flex: 1, // Makes each column take equal width
    textAlign: "left", // Align text to the left
    padding: 10, // Padding around the text
    borderRight: "1 solid #000", // Border right for each column
    borderBottom: "1 solid #000",
  },

  // Remove border for the last column (Amount)
  lastColumn: {
    borderRightWidth: 0, // No border for the last column
  },
  // Text style for each column
  columnText1: {
    fontSize: 12, // Adjust font size as needed
    textAlign: "left", // Left-align text
    fontFamily: "Collingar",
  },
  columnText: {
    fontSize: 12, // Adjust font size as needed
    textAlign: "left", // Left-align text
  },

  // Container for each row with Subtotal, Tax Rate, Tax, Total
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#f8f4ec",

  },

  // Column for labels (Subtotal, Tax Rate, Tax, Total)
  leftCol: {
    width: "75.12%",
    textAlign: "right",
    padding: 10,
    borderBottom: "1 solid #000",
    borderRight: "1 solid #000",
  },

  // Column for values (Tax Rate, Total)
  rightCol: {
    width: "24.88%",
    textAlign: "left",
    padding: 10,
    borderBottom: "1 solid #000"
  },

  // Text styling for the left column labels
  labelText: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Collingar",
  },

  // Text styling for the right column values
  colValueText: {
    fontSize: 12,
    // textAlign: "right",
  },
});

function PaymentStatus() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(""); // Payment status from URL
  const [orderDetails, setOrderDetails] = useState({
    customer_name: "",
    father_name: "",
    customer_phone: "",
    customer_email: "",
    emergency_contact: "",
    address: "",
    state: "",
    city: "",
    Date_of_birth: "",
    Id_Type: "",
    Id_Number: "",
    course_type: "",
    course_name: "",
    order_amount: "",
    duration: "",
    Date_Of_Enrollment: "",
  });

  const [loading, setLoading] = useState(true); // Track loading state
  const navigate = useNavigate();

  useEffect(() => {
    const decode = (value) => decodeURIComponent(value);

    // Extract customer and order details from URL query parameters
    const customerDetails = {
      customer_name: decode(searchParams.get("FullName")),
      father_name: decode(searchParams.get("FatherName")),
      customer_phone: decode(searchParams.get("Mobile")),
      customer_email: decode(searchParams.get("Email")),
      emergency_contact: decode(searchParams.get("EmergencyContact")),
      address: decode(searchParams.get("Address")),
      state: decode(searchParams.get("State")),
      city: decode(searchParams.get("City")),
      Id_Type: decode(searchParams.get("Id_type")),
      Id_Number: decode(searchParams.get("Id_number")),
      Date_of_birth: decode(searchParams.get("DOB")),
    
      course_type: decode(searchParams.get("Course_Type")),
      course_name: decode(searchParams.get("Course_Name")),
      order_amount: decode(searchParams.get("Amount")),
      duration: decode(searchParams.get("Duration")),
      Date_Of_Enrollment: decode(searchParams.get("Date_Of_Enrollment")),
    };

    setOrderDetails(customerDetails);

    // Extract and set payment status from URL
    const paymentStatus = decode(searchParams.get("order_status"));
    setStatus(paymentStatus);

    setLoading(false); // Set loading to false after data is ready
  }, [searchParams]);

  const AmountTotal=orderDetails.order_amount;
  const ActualAmount=(AmountTotal*100)/118;
  const tax=ActualAmount*0.18;
  const rat=18;

  const handleRedirect = () => {
    navigate("/"); // Redirect to home page
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Define the content for the PDF document
  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        {/* Header Box with Logo and Invoice */}
        <View style={styles.headerBox}>
          {/* Logo Box */}
          <View style={styles.logoBox}>
            <Image
              style={styles.logo}
              src="images/ocupielogoblack-png.png" // Replace this with the path to your logo image
            />
          </View>

          {/* Invoice Box */}
          <View style={styles.invoiceBox}>
            <Text style={styles.invoiceText}>Invoice</Text>
          </View>
        </View>
        <View style={styles.companyAddress}>
          <View style={styles.column1}>
            <Text style={styles.companyAddressChiled1}>
              Guwahati, Assam- 781011
            </Text>
          </View>

          <View style={styles.column2}>
            <Text style={styles.companyAddressChiled2}>
              contact@ocupiie.com
            </Text>
          </View>

          <View style={styles.column3}>
            <Text style={styles.companyAddressChiled3}>+91 361 405 2618</Text>
          </View>
        </View>

        <View style={styles.billingPageP}>
          <View style={styles.billingAddress}>
            <Text style={styles.billedTo}>Billed To:</Text>
            <Text style={styles.invoiceNo}>Invoice No:</Text>
          </View>

          <View style={styles.customerInfo}>
            <View style={styles.leftColumn}>
              <Text style={styles.customerText}>Customer's Name:-{orderDetails.customer_name}</Text>
              <Text style={styles.customerText}>Address:-{orderDetails.address}</Text>
            </View>
            <View style={styles.rightColumn}>
              <Text style={styles.customerChildText1}>547845</Text>
              <Text style={styles.customerChildText2}>Invoice Date:</Text>
              <Text style={styles.customerChildText3}>{orderDetails.Date_Of_Enrollment}</Text>
            </View>
          </View>
        </View>

        <View style={styles.descriptionpage}>
          {/* Four-column section */}
          <View style={styles.columnsContainer}>
            <View style={styles.columndes}>
              <Text style={styles.columnText1}>Description</Text>
            </View>
            <View style={styles.columndes}>
              <Text style={styles.columnText1}>Rate</Text>
            </View>
            <View style={styles.columndes}>
              <Text style={styles.columnText1}>Hours</Text>
            </View>
            <View style={[styles.columndes, styles.lastColumn]}>
              <Text style={styles.columnText1}>Amount</Text>
            </View>
          </View>

          {/* Content row 1 */}
          <View style={styles.columnsContainer}>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}>{orderDetails.course_name}</Text>
            </View>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}>{ActualAmount}</Text>
            </View>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}>{orderDetails.duration}</Text>
            </View>
            <View style={[styles.columndes1, styles.lastColumn]}>
              <Text style={styles.columnText}>{ActualAmount}</Text>
            </View>
          </View>

          {/* Content row 2 */}
          <View style={styles.columnsContainer}>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}></Text>
            </View>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}></Text>
            </View>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}></Text>
            </View>
            <View style={[styles.columndes1, styles.lastColumn]}>
              <Text style={styles.columnText}></Text>
            </View>
          </View>

          {/* Content row 3 */}
          <View style={styles.columnsContainer}>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}></Text>
            </View>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}></Text>
            </View>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}></Text>
            </View>
            <View style={[styles.columndes1, styles.lastColumn]}>
              <Text style={styles.columnText}></Text>
            </View>
          </View>

          {/* Content row 4 */}
          <View style={styles.columnsContainer}>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}></Text>
            </View>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}></Text>
            </View>
            <View style={styles.columndes1}>
              <Text style={styles.columnText}></Text>
            </View>
            <View style={[styles.columndes1, styles.lastColumn]}>
              <Text style={styles.columnText}></Text>
            </View>
          </View>

          {/* Subtotal Row */}
          <View style={styles.rowContainer}>
            <View style={styles.leftCol}>
              <Text style={styles.labelText}>Subtotal</Text>
            </View>
            <View style={styles.rightCol}>
              <Text style={styles.colValueText}>{ActualAmount}</Text>
            </View>
          </View>

          {/* Tax Rate Row */}
          <View style={styles.rowContainer}>
            <View style={styles.leftCol}>
              <Text style={styles.labelText}>Tax Rate (%)</Text>
            </View>
            <View style={styles.rightCol}>
              <Text style={styles.colValueText}>{rat}</Text>
            </View>
          </View>

          {/* Tax Row */}
          <View style={styles.rowContainer}>
            <View style={styles.leftCol}>
              <Text style={styles.labelText}>Tax</Text>
            </View>
            <View style={styles.rightCol}>
              <Text style={styles.colValueText}>{tax}</Text>
            </View>
          </View>

          {/* Total Row */}
          <View style={styles.rowContainer}>
            <View style={styles.leftCol}>
              <Text style={styles.labelText}>Total</Text>
            </View>
            <View style={styles.rightCol}>
              <Text style={styles.colValueText}>{orderDetails.order_amount}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <Container className="mt-4">
      <h2>Enrollment Details</h2>

      <Form>
        {/* Form rendering as before */}
        <Row>
          <Col>
            <Form.Group as={Col} controlId="oderId">
              <Form.Label>
                <strong>Order ID:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={searchParams.get("order_id")}
                disabled
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="status">
              <Form.Label>
                <strong>Payment Status:</strong>
              </Form.Label>
              <Form.Control type="text" value={status} disabled />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group as={Col} controlId="fullName">
              <Form.Label>
                <strong>Name:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.customer_name}
                disabled
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="fatherName">
              <Form.Label>
                <strong>Father's Name:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.father_name}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group as={Col} controlId="email">
              <Form.Label>
                <strong>Email:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.customer_email}
                disabled
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="phone">
              <Form.Label>
                <strong>Phone:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.customer_phone}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group as={Col} controlId="emergencyContact">
              <Form.Label>
                <strong>Emergency Contact:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.emergency_contact}
                disabled
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="dateofbirth">
              <Form.Label>
                <strong>Date of Birth</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.Date_of_birth}
                
                disabled
              />
            </Form.Group>
          </Col>
          </Row>
          <Row>
          <Col>
            <Form.Group as={Col} controlId="idtype">
              <Form.Label>
                <strong>Id Type</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.Id_Type}
                disabled
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="idnumber">
              <Form.Label>
                <strong>Id Number</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.Id_Number}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group as={Col} controlId="address">
              <Form.Label>
                <strong>Address:</strong>
              </Form.Label>
              <Form.Control type="text" value={orderDetails.address} disabled />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group as={Col} controlId="state">
              <Form.Label>
                <strong>State:</strong>
              </Form.Label>
              <Form.Control type="text" value={orderDetails.state} disabled />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="city">
              <Form.Label>
                <strong>City:</strong>
              </Form.Label>
              <Form.Control type="text" value={orderDetails.city} disabled />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group as={Col} controlId="courseType">
              <Form.Label>
                <strong>Course Type:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.course_type}
                disabled
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="courseName">
              <Form.Label>
                <strong>Course Name:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.course_name}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group as={Col} controlId="orderAmount">
              <Form.Label>
                <strong>Amount:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.order_amount}
                disabled
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Col} controlId="duration">
              <Form.Label>
                <strong>Duration:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.duration}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group as={Col} controlId="enrollmentDate">
              <Form.Label>
                <strong>Date of Enrollment:</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={orderDetails.Date_Of_Enrollment}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={handleRedirect} className="mt-3">
              Home
            </Button>
          </Col>
        </Row>
      </Form>

      {/* React-PDF Download Link */}

      <div>
      {/* Conditionally render download option only if payment status is 'Paid' */}
      {status === "paid" ? (
        <PDFDownloadLink
          document={<MyDocument />}
          fileName={`order_${new URLSearchParams(window.location.search).get("order_id") || "unknown"}.pdf`}
        >
          {({ loading }) =>
            loading ? (
              <Button variant="primary">Loading PDF...</Button>
            ) : (
              <Button variant="primary">Download Payment receipt</Button>
            )
          }
        </PDFDownloadLink>
      ) : (
        <p>Your payment is still pending.</p>
      )}
    </div>

    </Container>
  );
}

export default PaymentStatus;
