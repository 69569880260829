import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";

const BDMSidebar = () => {
  return (
    <div className="sidebar">
      <h2>BDM Dashboard</h2>
      <ListGroup>
        <ListGroup.Item action variant="light" as={Link} to="/bdm/bdmprofile">
          My Profile
        </ListGroup.Item>
        <ListGroupItem action variant="light" as={Link} to="/bdm/employer">
          Employer
        </ListGroupItem>
        <ListGroupItem action variant="light" as={Link} to="/bdm/jobseekers">
        Job Seekers
        </ListGroupItem>
        <ListGroupItem action variant="light" as={Link} to="/admin/feedback-inquiry">
        Language Sales
        </ListGroupItem>
        <ListGroupItem action variant="light" as={Link} to="/admin/payment-details">
        Monthly Revenue
        </ListGroupItem>

        <ListGroupItem action variant="light" as={Link} to="/admin/paid-payment-details">
        Employe Activity
        </ListGroupItem>
       
       <ListGroupItem action variant="light" as={Link} to= "/admin/franchise-form">
       Training Institute
       </ListGroupItem>
        <ListGroupItem action variant="light" as={Link} to="/admin/video-list">
        Security Guard
        </ListGroupItem>

      </ListGroup>
      
    </div>
  );
};

export default BDMSidebar;
